import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import { MatTableDataSource } from '@angular/material/table';
import { VoucherService } from 'src/app/services/voucher/voucher.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationServiceService } from 'src/app/services/notificationService/notification-service.service';
type AOA = any[][];

@Component({
  selector: 'app-voucher-bulk-upload',
  templateUrl: './voucher-bulk-upload.component.html',
  styleUrls: ['./voucher-bulk-upload.component.scss']
})
export class VoucherBulkUploadComponent implements OnInit {
  data: AOA = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  sampleHeader = [
    ['date', 'donaterName', 'panNumber', 'addressLine1', 'addressLine2',
      'donatedAmount', 'donatedAmountLetters', 'donationBy',
      'transactionNumber', 'chequeNumber', 'instrumentDate',
      'signaturePersonValue', 'signaturePersonPosstion'],
    ['2020-07-02', 'Name', 'pan number', 'Address 1', 'Address 2',
      100, 'One Thousand Rupees Only', 'BHIM-UPI',
      'id', '', '', 1, 'Founder & Chairman']
  ];
  fileName = 'sampleFile.xlsx';

  excelToJsonData;

  displayedColumns: string[] = [
    'date', 'donaterName', 'panNumber', 'addressLine1',
    'addressLine2', 'donatedAmount', 'donatedAmountLetters',
    'donationBy', 'transactionNumber', 'chequeNumber', 'instrumentDate',
    'signaturePersonValue', 'signaturePerson',
    'signaturePersonPosstion'];
  dataSource;
  showTable = false;

  @ViewChild('inputFile') myInputVariable: ElementRef;

  // tslint:disable-next-line: max-line-length
  constructor(private voucherService: VoucherService, private spinner: NgxSpinnerService,
              private notificationServiceService: NotificationServiceService) { }

  ngOnInit(): void {
  }


  onFileChange(evt: any) {
    const input = evt.target;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const fileData = reader.result;
      const wb = XLSX.read(fileData, { type: 'binary' });

      wb.SheetNames.forEach(sheetName => {
        const rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
        this.excelToJsonData = rowObj;
        this.jsonDataValidation();
      });
    };
    reader.readAsBinaryString(input.files[0]);
  }

  export(): void {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.sampleHeader);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  jsonDataValidation() {
    this.dataSource = new MatTableDataSource(this.excelToJsonData);
    this.showTable = true;
  }

  uploadFile() {
    this.spinner.show();
    this.voucherService.bulkUploadVoucher(this.excelToJsonData).subscribe(result => {
      this.notificationServiceService.showSnackBar('success', 'File Uploaded Successfully', 3000);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.notificationServiceService.showSnackBar('error', 'Error', 3000);
      console.error('error');
    });
    this.reset();
  }

  reset() {
    this.myInputVariable.nativeElement.value = '';
    this.dataSource = null;
    this.showTable = false;
  }
}

<section fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
  <mat-card>
    <form [formGroup]="loginForm">
    <mat-card-header fxLayoutAlign="center">
      <mat-card-title>
        Login
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>

    <mat-form-field appearance="outline">
      <mat-label>Enter your Email</mat-label>
      <input type="email" matInput placeholder="" formControlName="email">
      <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
      <mat-error *ngIf="loginForm.value.email.invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Enter your password</mat-label>
      <input matInput formControlName="password" [type]="hide ? 'password' : 'text'">
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
  </mat-card-content>
  <mat-card-footer fxLayoutAlign="center">
    <button mat-raised-button color="primary" [disabled]="!isSubmit()"  (click)="loginCredential()">Submit</button>
  </mat-card-footer>
</form>
  </mat-card>
</section>


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { LayoutComponent } from './components/admin/layout/layout.component';
import { VoucherOverviewComponent } from './components/voucher-overview/voucher-overview.component';
import { VoucherComponent } from './components/voucher-overview/voucher/voucher.component';
import { VoucherReportComponent } from './components/voucher-overview/voucher-report/voucher-report.component';
import { AllVoucherDetailService } from './resolver/all-voucher-detail/all-voucher-detail.service';
import { VoucherBulkUploadComponent } from './components/voucher-overview/voucher-bulk-upload/voucher-bulk-upload.component';
import { MemberRegisterComponent } from './components/website/member-register/member-register.component';
import {
  MonthWiseVoucherReportComponent
} from './components/voucher-overview/month-wise-voucher-report/month-wise-voucher-report.component';
import { MemberOverviewComponent } from './components/member-overview/member-overview/member-overview.component';
import { MemberEmailOverviewComponent } from './components/member-overview/member-email-overview/member-email-overview.component';
import { AccountsOverviewComponent } from './components/accounts-overview/accounts-overview/accounts-overview.component';
import { WebSiteOverviewComponent } from './components/website/web-site-overview/web-site-overview.component';
import { ContactComponent } from './components/website/contact/contact.component';
import { AboutComponent } from './components/website/about/about.component';
import { HomeComponent } from './components/website/home/home.component';
import { DonateUsPageComponent } from './components/website/donate-us-page/donate-us-page.component';
import { MembersDetailsComponent } from './components/member-overview/members-details/members-details.component';
import { GetMembersResolverService } from './resolver/get-members-resolver/get-members-resolver.service';
import { ImageGalleryComponent } from './components/website/image-gallery/image-gallery.component';
import { GetIdCardStatusCountService } from './resolver/getIdCardStatusCount/get-id-card-status-count.service';
import { BirthdayListComponent } from './components/member-overview/birthday-list/birthday-list.component';
import { BirthdayListResolverService } from './resolver/birthday-list-resolver/birthday-list-resolver.service';
import { MemberSocialMediaDetailsComponent } from './components/member-overview/member-social-media-details/member-social-media-details.component';
import { GetMembersSocialMediaDetailsService } from './resolver/get-members-social-media-details/get-members-social-media-details.service';
import { AgarHomeComponent } from './components/agar-website/agar-home/agar-home.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'memberRegister',
    component: MemberRegisterComponent
  },
  {
    path: 'gallery',
    component: ImageGalleryComponent
  },
  {
    path: 'donate',
    component: DonateUsPageComponent
  },


  /*
    {
      path: '',
      component: WebSiteOverviewComponent,
      children: [
        {
          path: '',
          component: HomeComponent
          // component: MemberRegisterComponent
        },
        {
          path: 'contact',
          component: ContactComponent
        },
        {
          path: 'login',
          component: LoginComponent,
          pathMatch: 'full',

        },
        {
          path: 'about',
          component: AboutComponent
        },
        {
          path: 'memberRegister',
          component: MemberRegisterComponent
        },
        {
          path: 'donate',
          component: DonateUsPageComponent
        },
        {
          path: 'gallery',
          component: ImageGalleryComponent
        }
      ]
    },

    {
      path: 'app',
      component: LayoutComponent,
      canActivate: [AuthGuardService],
      children: [
        {
          path: 'dashboard',
          component: DashboardComponent,
          resolve: {
            idCardStatus: GetIdCardStatusCountService
          }
        },
        {
          path: 'voucher',
          component: VoucherOverviewComponent
        },
        {
          path: 'add-voucher',
          component: VoucherComponent
        },
        {
          path: 'bulk-upload-voucher',
          component: VoucherBulkUploadComponent
        },
        {
          path: 'report-voucher',
          component: VoucherReportComponent,
          resolve: {
            voucherDetail: AllVoucherDetailService
          }
        },
        {
          path: 'monthwise-report-voucher',
          component: MonthWiseVoucherReportComponent
        },
        {
          path: 'member',
          component: MemberOverviewComponent
        },
        {
          path: 'member-manage',
          component: MembersDetailsComponent,
          resolve: {
            allMemberDetail: GetMembersResolverService
          }
        },
        {
          path: 'member-birthday-list',
          component: BirthdayListComponent,
          resolve: {
            memberBirthdayDetail: BirthdayListResolverService
          }
        },
        {
          path: 'member-social-media-update',
          component: MemberSocialMediaDetailsComponent,
          resolve: {
            memberSocialMediaDetail: GetMembersSocialMediaDetailsService
          }
        },
        {
          path: 'email-manage',
          component: MemberEmailOverviewComponent
        },
        {
          path: 'accounts',
          component: AccountsOverviewComponent
        }

      ]
    }
    */
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { VoucherService } from 'src/app/services/voucher/voucher.service';

@Injectable()
export class AllVoucherDetailService implements Resolve<any>  {

  constructor(private voucherService: VoucherService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.voucherService.getAllVoucherData();
  }
}

<section class="pt-5 pb-5 container">
  <h1 class="pb-3 about-main-title text-center pad-10">Chronicle</h1>
  <div class="about-main-title-underline"></div>
  <p class="pt-5">
    On 6th June 2012, we a group of college students shared common motivation of helping needy people and decided to
    take initiative for it. As a result of continuous effort, on 25th October 2012, “Agarthulir Foundation” an NGO was
    launched by us. Our motto is ‘Lets sow the seeds for the needs’. We aim to offer our support and service to the
    people who are in need of education, medical support and orphanage support. FUNDS are heart for running the
    foundation. The complete funds for Agarthulir are from the members and volunteer and we don’t have any outsource
    funds.
  </p>
  <p class="pt-2">
    As it was completely run by the students, we faced a lot of obstacle initially (lack of guidance, money, time etc.,)
    But the HOPE we had on us and Agarthulir was the launching point for us to march against all the obstacles.
  </p>
  <p class="pt-2">
    <strong>A little progress each day adds up to big results.</strong>
  </p>

  <div class="about-point">
    <ul>
      <li class="pt-3"> Hitherto, Our services include, </li>
      <li class="pt-3"> Supported 35+ students to continue their higher studies </li>
      <li class="pt-3"> Visited 30+ Orphanages and provided essentials to 4500 + people (food, dress, books etc.,) </li>
      <li class="pt-3"> Supported for 25+ medical treatments on their crucial time and still continuing our best. </li>
    </ul>
  </div>
</section>

<div class="container text-center">
  <h2>Our motto is “Let’s Sow the Seeds for the Needs"</h2>
</div>

<section class="pt-3 pb-3 container">
  <div class="text-center">
    <button style="background-color: #F69520; border-color: #F69520" class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample"
      aria-expanded="false" aria-controls="collapseExample">
      Click To See Agarthulir Team
    </button>
  </div>
  <div class="collapse" id="collapseExample">
    <h1 class="pb-3 about-main-title text-center">Team of Agarthulir</h1>
    <div class="about-main-title-underline"></div>
    <div class="row justify-content-center">
      <div *ngFor="let member of AFTeamMember">

        <div class="gallery">
          <img width="200px" height="300px" class="cir" src="{{member.photo}}">
          <div class="desc">
            <h4>{{member.name}}</h4>
            <p>{{member.position}}</p>
          </div>

        </div>

        <!-- <div class="col-lg-3 mt-4 text-center">
          <div>
          <img class="face-img" src="{{member.photo}}">
        </div>
          <h4>{{member.name}}</h4>
          <p>{{member.position}}</p>
        </div> -->
      </div>
      <!-- <div class="col-lg-3 mt-4 text-center">
        <div class="p-3">
          <div class="face-img"></div>
        </div>
        <h4>Shrinathan</h4>
        <p>Admin/Account Officer</p>
      </div>
      <div class="col-lg-3 mt-4 text-center">
        <div class="p-3">
          <div class="face-img"></div>
        </div>
        <h4>Shrinathan</h4>
        <p>Admin/Account Officer</p>
      </div>
      <div class="col-lg-3 mt-4 text-center">
        <div class="p-3">
          <div class="face-img"></div>
        </div>
        <h4>Shrinathan</h4>
        <p>Admin/Account Officer</p>
      </div>
      <div class="col-lg-3 mt-4 text-center">
        <div class="p-3">
          <div class="face-img"></div>
        </div>
        <h4>Shrinathan</h4>
        <p>Admin/Account Officer</p>
      </div>
      <div class="col-lg-3 mt-4 text-center">
        <div class="p-3">
          <div class="face-img"></div>
        </div>
        <h4>Shrinathan</h4>
        <p>Admin/Account Officer</p>
      </div>
      <div class="col-lg-3 mt-4 text-center">
        <div class="p-3">
          <div class="face-img"></div>
        </div>
        <h4>Shrinathan</h4>
        <p>Admin/Account Officer</p>
      </div>
      <div class="col-lg-3 mt-4 text-center">
        <div class="p-3">
          <div class="face-img"></div>
        </div>
        <h4>Shrinathan</h4>
        <p>Admin/Account Officer</p>
      </div>
      <div class="col-lg-3 mt-4 text-center">
        <div class="p-3">
          <div class="face-img"></div>
        </div>
        <h4>Shrinathan</h4>
        <p>Admin/Account Officer</p>
      </div>
      <div class="col-lg-3 mt-4 text-center">
        <div class="p-3">
          <div class="face-img"></div>
        </div>
        <h4>Shrinathan</h4>
        <p>Admin/Account Officer</p>
      </div>
      <div class="col-lg-3 mt-4 text-center">
        <div class="p-3">
          <div class="face-img"></div>
        </div>
        <h4>Shrinathan</h4>
        <p>Admin/Account Officer</p>
      </div>
      <div class="col-lg-3 mt-4 text-center">
        <div class="p-3">
          <div class="face-img"></div>
        </div>
        <h4>Shrinathan</h4>
        <p>Admin/Account Officer</p>
      </div>
      <div class="col-lg-3 mt-4 text-center">
        <div class="p-3">
          <div class="face-img"></div>
        </div>
        <h4>Shrinathan</h4>
        <p>Admin/Account Officer</p>
      </div>
      <div class="col-lg-3 mt-4 text-center">
        <div class="p-3">
          <div class="face-img"></div>
        </div>
        <h4>Shrinathan</h4>
        <p>Admin/Account Officer</p>
      </div> -->
    </div>
  </div>
</section>

<section class="pt-5 pb-5 container">
  <h1 class="pb-3 journey-main-title text-center">Our Journey</h1>
  <div class="journey-main-title-underline"></div>

  <div class="container pt-5 pb-5 mt-5 agenta">
    <div *ngFor="let data of journyList">
      <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-2 pb-4 mt-5 agenta-mobile-year">
          <span class="year-text">{{data.year}}</span>
        </div>
        <div class="col-lg-4 text-rights">
          <h3 class="month-text">{{data.month1}}</h3>
          <!-- <h4>Started</h4> -->
          <p>{{data.text1}}</p>
        </div>
        <div class="col-lg-2 text-center mt-5 agenta-mobile-years">
          <span class="year-text">{{data.year}}</span>
        </div>
        <div class="col-lg-4 text-left ">
          <h3 class="month-text">{{data.month2}}</h3>
          <!-- <h4>SSET</h4> -->
          <p>{{data.text2}}</p>
        </div>
        <div class="col-lg-1"></div>
      </div>
      <div class="row agenta-mobile justify-content-center">
        <img src="assets/images/line.png" height="200px" alt="">
      </div>
      <!-- <div class="row">
      <div class="col-lg-1"></div>
      <div class="col-lg-2 pb-4 mt-5 agenta-mobile-year">
        <span class="year-text">2012</span>
      </div>
      <div class="col-lg-4 text-rights">
        <h3 class="month-text">Jul</h3>
        <h4>Started</h4>
        <p>Journey to support Poor
          Orphanages Started
        </p>
      </div>
      <div class="col-lg-2 text-center mt-5 agenta-mobile-years">
        <span class="year-text">2012</span>
      </div>
      <div class="col-lg-4 text-left ">
        <h3 class="month-text">Jun</h3>
        <h4>SSET</h4>
        <p>Small trust named SSET was formed by
          a group of college students
        </p>
      </div>
      <div class="col-lg-1"></div>
    </div>
    <div class="row agenta-mobile justify-content-center">
      <img src="assets/images/line.png" height="200px" alt="">
    </div>
    <div class="row">
      <div class="col-lg-1"></div>
      <div class="col-lg-2 pb-4 mt-5 agenta-mobile-year">
        <span class="year-text">2012</span>
      </div>
      <div class="col-lg-4 text-rights">
        <h3 class="month-text">Jul</h3>
        <h4>Started</h4>
        <p>Journey to support Poor
          Orphanages Started
        </p>
      </div>
      <div class="col-lg-2 text-center mt-5 agenta-mobile-years">
        <span class="year-text">2012</span>
      </div>
      <div class="col-lg-4 text-left ">
        <h3 class="month-text">Jun</h3>
        <h4>SSET</h4>
        <p>Small trust named SSET was formed by
          a group of college students
        </p>
      </div>
      <div class="col-lg-1"></div>
    </div>
    <div class="row agenta-mobile justify-content-center">
      <img src="assets/images/line.png" height="200px" alt="">
    </div>
    <div class="row">
      <div class="col-lg-1"></div>
      <div class="col-lg-2 pb-4 mt-5 agenta-mobile-year">
        <span class="year-text">2012</span>
      </div>
      <div class="col-lg-4 text-rights">
        <h3 class="month-text">Jul</h3>
        <h4>Started</h4>
        <p>Journey to support Poor
          Orphanages Started
        </p>
      </div>
      <div class="col-lg-2 text-center mt-5 agenta-mobile-years">
        <span class="year-text">2012</span>
      </div>
      <div class="col-lg-4 text-left ">
        <h3 class="month-text">Jun</h3>
        <h4>SSET</h4>
        <p>Small trust named SSET was formed by
          a group of college students
        </p>
      </div>
      <div class="col-lg-1"></div>
    </div>
    <div class="row agenta-mobile justify-content-center">
      <img src="assets/images/line.png" height="200px" alt="">
    </div>
    <div class="row">
      <div class="col-lg-1"></div>
      <div class="col-lg-2 pb-4 mt-5 agenta-mobile-year">
        <span class="year-text">2012</span>
      </div>
      <div class="col-lg-4 text-rights">
        <h3 class="month-text">Jul</h3>
        <h4>Started</h4>
        <p>Journey to support Poor
          Orphanages Started
        </p>
      </div>
      <div class="col-lg-2 text-center mt-5 agenta-mobile-years">
        <span class="year-text">2012</span>
      </div>
      <div class="col-lg-4 text-left ">
        <h3 class="month-text">Jun</h3>
        <h4>SSET</h4>
        <p>Small trust named SSET was formed by
          a group of college students
        </p>
      </div>
      <div class="col-lg-1"></div>
    </div>
    <div class="row agenta-mobile justify-content-center">
      <img src="assets/images/line.png" height="200px" alt="">
    </div>
    <div class="row">
      <div class="col-lg-1"></div>
      <div class="col-lg-2 pb-4 mt-5 agenta-mobile-year">
        <span class="year-text">2012</span>
      </div>
      <div class="col-lg-4 text-rights">
        <h3 class="month-text">Jul</h3>
        <h4>Started</h4>
        <p>Journey to support Poor
          Orphanages Started
        </p>
      </div>
      <div class="col-lg-2 text-center mt-5 agenta-mobile-years">
        <span class="year-text">2012</span>
      </div>
      <div class="col-lg-4 text-left ">
        <h3 class="month-text">Jun</h3>
        <h4>SSET</h4>
        <p>Small trust named SSET was formed by
          a group of college students
        </p>
      </div>
      <div class="col-lg-1"></div>
    </div> -->
    </div>
  </div>

</section>

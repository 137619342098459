import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as jspdf from 'jspdf';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce } from 'ng-animate';
// import { bounce } from 'ng-animate/lib/bouncing';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('bounce', [transition('* => *', useAnimation(bounce, {
      // Set the duration to 5seconds and delay to 2seconds
      params: { timing: 5, delay: 2 }
    }))])

  ],
})
export class HomeComponent implements OnInit {

  reviewList = [
    {
      name: 'Prof V. Ramakrishnan',
      designation: 'Former Director, IISER TVM',
      review: 'Delighted to know a team of young minds working for better social well being of several families. I am happy to note that transparency, ethical codes is adopted by them to fulfil their charitable work.',
      url: 'assets/images/review/1.jpg'
    },
    {
      name: 'Prof J. Kumar',
      designation: 'Board Director - Anna University',
      review: 'Agarthulir is a hope to the young people of not only in Tamil Nadu but also to neighboring states, the group from inception without any specific attachments is giving its heartful support to the society.',
      url: 'assets/images/review/2.jpg'
    },
    {
      name: 'Dr. Geetha Ramadas',
      designation: 'H.O.D , R.M.K. Engineering College',
      review: 'The organization is extending helping hand to many sections of society like School Children, patients and elderly. This website is surely a feather on its cap. Wishing many success for Agarthulir.',
      url: 'assets/images/review/3.jpg'
    },
    {
      name: 'M. Revathi',
      designation: 'Associate Manager, SBI',
      review: 'I sincerely have to thank Agarthulir for giving me an opportunity and laying a platform to help other people in need. I applaud their dedication, Courage, exceptional support over the years.',
      url: 'assets/images/review/4.jpg'
    },
    {
      name: 'Kazuaki Yaegashi',
      designation: 'Design Engineer, Toyota R&D',
      review: 'I didn’t know Agarthulir until I met Shri, but now I understand Agarthulir purpose, motivation and dreams. As much as possible, and until I live in this Earth I’ll support Agarthulir.',
      url: 'assets/images/review/5.jpg'
    },
    {
      name: 'G. Prabakaran',
      designation: 'Junior Assistant in Tiruvallur Police Office',
      review: 'I know Agarthulir since 2012. At the time of flood the foundation helped to many of people\'s across the Tamil Nadu. The foundation helped my friend father in serious condition due to Cancer.',
      url: 'assets/images/review/6.jpg'
    }
  ]
  constructor() { }

  ngOnInit() {
  }

  onEdit() {
    window.scrollTo(0, 0);
  }
}

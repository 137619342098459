<div class="container">

  <!-- <div class="mt-5">
    <div class="text-center">
      <h1>Namakkal Event</h1>
    </div>
    <div style="width: 100%; text-align:center;margin:0; padding:0;">
      <ng-image-slider #nav [images]="imageObject" [infinite]="true" [autoSlide]="1"
        [imageSize]="{width: '25%', height: 200}" slideImage="1">
      </ng-image-slider>
    </div>
  </div>
  <div class="mt-5">
    <div class="text-center">
      <h1>Namakkal Event</h1>
    </div>
    <div style="width: 100%; text-align:center;margin:0; padding:0;">
      <ng-image-slider #nav [images]="imageObject" [infinite]="false" [autoSlide]="1"
        [imageSize]="{width: '25%', height: 200}" slideImage="1">
      </ng-image-slider>
    </div>
  </div> -->
  <div class="mt-5">
    <div class="text-center">
      <h1>A.P.J. Abdul Kalam Sir Event</h1>
    </div>
    <div style="width: 100%; text-align:center;margin:0; padding:0;">
      <ng-image-slider #nav [images]="imageObject" [infinite]="false" [autoSlide]="1"
        [imageSize]="{width: '25%', height: 200}" slideImage="1">
      </ng-image-slider>
    </div>
  </div>

  <div class="mt-5">
    <div class="text-center">
      <h1>Namakkal School Event</h1>
    </div>
    <div style="width: 100%; text-align:center;margin:0; padding:0;">
      <ng-image-slider #nav [images]="imageObject4" [infinite]="false" [autoSlide]="1"
        [imageSize]="{width: '25%', height: 200}" slideImage="1">
      </ng-image-slider>
    </div>
  </div>

  <div class="mt-5">
    <div class="text-center">
      <h1>Clean India</h1>
    </div>
    <div style="width: 100%; text-align:center;margin:0; padding:0;">
      <ng-image-slider #nav [images]="imageObject6" [infinite]="false" [autoSlide]="1"
        [imageSize]="{width: '25%', height: 200}" slideImage="1">
      </ng-image-slider>
    </div>
  </div>

  <div class="mt-5">
    <div class="text-center">
      <h1>Physical challenge school</h1>
    </div>
    <div style="width: 100%; text-align:center;margin:0; padding:0;">
      <ng-image-slider #nav [images]="imageObject1" [infinite]="false" [autoSlide]="1"
        [imageSize]="{width: '25%', height: 200}" slideImage="1">
      </ng-image-slider>
    </div>
  </div>

  <div class="mt-5">
    <div class="text-center">
      <h1>Gurumalai Event</h1>
    </div>
    <div style="width: 100%; text-align:center;margin:0; padding:0;">
      <ng-image-slider #nav [images]="imageObject2" [infinite]="false" [autoSlide]="1"
        [imageSize]="{width: '25%', height: 200}" slideImage="1">
      </ng-image-slider>
    </div>
  </div>

  <div class="mt-5">
    <div class="text-center">
      <h1>Jesus Trust Event</h1>
    </div>
    <div style="width: 100%; text-align:center;margin:0; padding:0;">
      <ng-image-slider #nav [images]="imageObject3" [infinite]="false" [autoSlide]="1"
        [imageSize]="{width: '25%', height: 200}" slideImage="1">
      </ng-image-slider>
    </div>
  </div>

  <div class="mt-5">
    <div class="text-center">
      <h1>Shore Home Event</h1>
    </div>
    <div style="width: 100%; text-align:center;margin:0; padding:0;">
      <ng-image-slider #nav [images]="imageObject5" [infinite]="false" [autoSlide]="1"
        [imageSize]="{width: '25%', height: 200}" slideImage="1">
      </ng-image-slider>
    </div>
  </div>

  <div class="mt-5">
    <div class="text-center">
      <h1>Video</h1>
    </div>
    <div style="width: 100%; text-align:center;margin:0; padding:0;">
      <ng-image-slider #nav [images]="imageObject7" [infinite]="false" [autoSlide]="1"
        [imageSize]="{width: '25%', height: 200}" slideImage="1">
      </ng-image-slider>
    </div>
  </div>

</div>

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LayoutComponent } from './components/admin/layout/layout.component';
import { SideNavgationComponent } from './components/admin/side-navgation/side-navgation.component';
import { TopNavgationComponent } from './components/admin/top-navgation/top-navgation.component';
import { DonationTypeResolver } from './resolver/donation-type/donatio-type';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { VoucherOverviewComponent } from './components/voucher-overview/voucher-overview.component';
import { VoucherReportComponent } from './components/voucher-overview/voucher-report/voucher-report.component';
import { VoucherComponent } from './components/voucher-overview/voucher/voucher.component';
import { AllVoucherDetailService } from './resolver/all-voucher-detail/all-voucher-detail.service';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { VoucherBulkUploadComponent } from './components/voucher-overview/voucher-bulk-upload/voucher-bulk-upload.component';
import { MemberRegisterComponent } from './components/website/member-register/member-register.component';
import { MonthWiseVoucherReportComponent } from './components/voucher-overview/month-wise-voucher-report/month-wise-voucher-report.component';
import { MemberOverviewComponent } from './components/member-overview/member-overview/member-overview.component';
import { MemberEmailOverviewComponent } from './components/member-overview/member-email-overview/member-email-overview.component';
import { AccountsOverviewComponent } from './components/accounts-overview/accounts-overview/accounts-overview.component';
import { NgxEditorModule } from 'ngx-editor';
import { menu, placeholder, schema } from 'ngx-editor';
import { MatChipsModule } from '@angular/material/chips';
import { QuillModule } from 'ngx-quill';
import { AboutComponent } from './components/website/about/about.component';
import { ContactComponent } from './components/website/contact/contact.component';
import { FooterComponent } from './components/website/footer/footer.component';
import { WebSiteOverviewComponent } from './components/website/web-site-overview/web-site-overview.component';
import { HomeComponent } from './components/website/home/home.component';
import { TopNavComponent } from './components/website/top-nav/top-nav.component';
import { DonateUsPageComponent } from './components/website/donate-us-page/donate-us-page.component';
import { MembersDetailsComponent } from './components/member-overview/members-details/members-details.component';
import { ImageGalleryComponent } from './components/website/image-gallery/image-gallery.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PersonLightBoxComponent } from './components/member-overview/person-light-box/person-light-box.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { NgImageSliderModule } from 'ng-image-slider';
import { BirthdayListComponent } from './components/member-overview/birthday-list/birthday-list.component';
import { MemberSocialMediaDetailsComponent } from './components/member-overview/member-social-media-details/member-social-media-details.component';
import { AgarHomeComponent } from './components/agar-website/agar-home/agar-home.component';
import { HeaderComponent } from './components/agar-website/header/header.component';
import { AgarFooterComponent } from './components/agar-website/agar-footer/agar-footer.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    FlexLayoutModule,
    MatMenuModule,
    MatCardModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    FormsModule,
    MatChipsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    // NgxEditorModule,
    NgxEditorModule.forRoot({
      schema, // optional scheama, see https://sibiraj.dev/ngx-editor/additional-documentation/schema.html
      plugins: [
        menu({
          // default options (Optional)
          toolbar: [
            ['bold', 'italic', 'code'], // inline icons
            ['ordered_list', 'bullet_list'],
            [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
            // [codemirror]
          ],
          labels: {
            bold: 'Bold',
            italics: 'Italics',
            code: 'Code',
            ordered_list: 'Ordered List',
            bullet_list: 'Bullet List',
            heading: 'Heading'
          }
        }),
        placeholder('Type something here...')
      ],
      nodeViews: {} // optional, see https://prosemirror.net/examples/footnote/
    }),
    QuillModule.forRoot(),
    NgImageSliderModule,
    MatSortModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    DashboardComponent,
    SideNavComponent,
    TopNavComponent,
    ContactComponent,
    AboutComponent,
    WebSiteOverviewComponent,
    LayoutComponent,
    SideNavgationComponent,
    TopNavgationComponent,
    VoucherOverviewComponent,
    VoucherReportComponent,
    VoucherComponent,
    FooterComponent,
    VoucherBulkUploadComponent,
    MemberRegisterComponent,
    MonthWiseVoucherReportComponent,
    MemberOverviewComponent,
    MemberEmailOverviewComponent,
    AccountsOverviewComponent,
    DonateUsPageComponent,
    MembersDetailsComponent,
    ImageGalleryComponent,
    PersonLightBoxComponent,
    BirthdayListComponent,
    MemberSocialMediaDetailsComponent,
    AgarHomeComponent,
    HeaderComponent,
    AgarFooterComponent
  ],

  providers: [
    DonationTypeResolver, AllVoucherDetailService
  ],
  entryComponents: [
    PersonLightBoxComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

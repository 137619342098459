import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationServiceService } from 'src/app/services/notificationService/notification-service.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  hide = true;
  loginForm: FormGroup;

  constructor(private loginService: LoginService, private router: Router, private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder, private notificationServiceService: NotificationServiceService) {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.email],
      password: ['', Validators.required]
    });

  }

  ngOnInit() {
  }

  loginCredential() {
    if (this.loginForm.valid) {
      this.spinner.show();

      this.loginService.loginCredential(this.loginForm.value).subscribe(result => {
        this.notificationServiceService.showSnackBar('success', 'Authentication Succeed', 3000);
        localStorage.setItem('token', result.token);
        localStorage.setItem('isLogin', result.message.isLogin);
        this.router.navigate(['app/dashboard']);
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        this.notificationServiceService.showSnackBar('error', 'Username or password wrong', 3000);
      });
    } else {
      this.spinner.hide();
      this.notificationServiceService.showSnackBar('error', 'Errorn in server', 3000);
      localStorage.setItem('isLogin', 'false');
      localStorage.removeItem('token');
    }
  }

  isSubmit(): boolean {
    return this.loginForm.valid ? true : false;
  }

  getErrorMessage() {
    if (this.loginForm.value.email.hasError('required')) {
      return 'You must enter a value';
    }


    return this.loginForm.value.email.hasError('email') ? 'Not a valid email' : '';
  }

}

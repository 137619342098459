<div class = "footer" fxLayoutAlign='center'>
  <span class = "foundation-color">Agarthulir Foundation</span>
  <span class = "footer-color">@2013.All Right Reserved</span>
</div>
<div class="flexdisplay" fxLayoutAlign='center'>
  <span class= "icon-padding"><a href="https://www.facebook.com/agarthulir.india"><img src="../../../assets/img/facebook.png"/></a></span>
  <span class= "icon-padding"><a href="https://twitter.com/agarthulir"><img src="../../../assets/img/twitter.png"/></a></span>
  <span class= "icon-padding"><a href="https://www.instagram.com/agarthulir_foundation/"><img src="../../../assets/img/instagram.png"/></a></span>
  <span class= "icon-padding"><a href="https://www.youtube.com/channel/UC5LDsyvFehbGEAtcA2XE_MQ"><img src="../../../assets/img/youtube.png"/></a></span>
</div>

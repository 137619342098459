import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigurationService} from '../configuration/configuration.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MembersService {

  constructor(private http: HttpClient, private configurationService: ConfigurationService) {
  }

  register(memberDetails: any): Observable<any> {
    return this.http.post(this.configurationService.getHostUrl() + '/member', memberDetails);
  }

  getDataImage(body) {
    const url = this.configurationService.getHostUrl() + '/google/callback';

    return new Promise((res) => {
      this.http.post(url, body).subscribe(data => {
        res(data);
      }, err => {
        console.error(err);
      });
    });
  }

  getAllMembers(): Observable<any> {
    return this.http.get(this.configurationService.getHostUrl() + '/member/allMembers');
  }

  getMemberById(id): Observable<any> {
    return this.http.get(this.configurationService.getHostUrl() + `/member/getMember/${id}`);
  }

  getIdCardStatusCount(): Observable<any> {
    return this.http.get(this.configurationService.getHostUrl() + `/member/getIdCardStatusCount`);
  }

  updateIDCardStatus(updateData): Observable<any> {
    return this.http.put(this.configurationService.getHostUrl() + '/member/updateIDCard', updateData);
  }

  getBirthDayPerson(): Observable<any> {
    return this.http.get(this.configurationService.getHostUrl() + `/member/getBirthdayList`);
  }

  getSocialMediaDetails(): Observable<any> {
    return this.http.get(this.configurationService.getHostUrl() + `/member/getSocialMediaDetails`);
  }

  updateSocialMediaDetail(updateDetails: any): Observable<any>  {
    return this.http.put(this.configurationService.getHostUrl() + '/member/updateSocialMedia', updateDetails);
  }

}

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
  <div>
    <button mat-raised-button color="accent" [routerLink]="['/app/member']">Back</button>
  </div>

  <div>
    <button mat-raised-button (click)="export()">Download XLSX File</button>
  </div>

  <div class="example-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>
  </div>
</div>


<div class="mt-10 mat-elevation-z8">
  <table class="with-100" mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="imageBase64textString">
      <th mat-header-cell *matHeaderCellDef> Image</th>
      <td mat-cell *matCellDef="let element">
        <a href="https://drive.google.com/uc?export=view&id={{element.imageBase64textString}}" target="_blank">
          <img width="50" height="50" src="https://drive.google.com/uc?export=view&id={{element.imageBase64textString}}"
               alt="Image"/>
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
      <td mat-cell *matCellDef="let element">{{element.firstName}}</td>
    </ng-container>


    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile</th>
      <td mat-cell *matCellDef="let element"> {{element.mobile}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="blood_group">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> B-Group</th>
      <td mat-cell *matCellDef="let element"> {{element.blood_group}} </td>
    </ng-container>

    <ng-container matColumnDef="id_card_status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Id Card Status</th>
      <td mat-cell *matCellDef="let element" >
        <div  *ngIf="element.id_card_status === 1"><span>Request Received </span> <span class="pink-dot"></span></div>
        <div  *ngIf="element.id_card_status === 2"><span>ID Card in process </span><span class="yellow-dot"></span></div>
        <div  *ngIf="element.id_card_status === 3"><span>Delivered </span><span class="green-dot"></span></div>
      </td>
    </ng-container>

    <ng-container matColumnDef="settingsBtn">
      <th mat-header-cell *matHeaderCellDef> Settings</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="openPersonDialog(element)">
          <mat-icon>settings</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>
</div>



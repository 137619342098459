import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from 'src/app/components/notification/notification.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationServiceService {

  constructor(public snackBar: MatSnackBar) { }

  public showSnackBar(action: string, message: string, duration: number) {
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        action: action,
        message: message
      },
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      duration: duration
    });
  }
}

<!-- <div class="container">
  <div *ngIf="registerFormDisplay">
    <div fxLayout="row" class="mt-3" fxLayoutAlign="center center">
      <h2 class="font-weight-bold">Member ID Card Form</h2>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">
      <form [formGroup]="registerForm" [ngStyle.gt-md]="{'width': '40%'}">
        <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px">
          <mat-form-field fxLayout="column" class="with-100" appearance="outline">
            <mat-label>Full Name*</mat-label>
            <input matInput type="text" formControlName="fullName" placeholder="">
          </mat-form-field>

          <mat-form-field fxLayout="column" class="with-100" appearance="outline">
            <mat-label>Email*</mat-label>
            <input type="email" matInput formControlName="email" placeholder="">
            <mat-error>Enter the Correct Email Id</mat-error>
          </mat-form-field>

          <mat-form-field fxLayout="column" class="with-100" appearance="outline">
            <mat-label>Mobile Number*</mat-label>
            <input matInput type="number" onKeyPress="if(this.value.length==10) return false;" maxlength="10" min="1"
                   formControlName="mobileNumber" placeholder="">
            <mat-error>Enter the Correct Mobile Number</mat-error>
          </mat-form-field>

          <mat-form-field fxLayout="column" class="with-100" appearance="outline">
            <mat-label id="dateOfBirthLabel">DateOfBirth*</mat-label>
            <input matInput formControlName="dataOfBirth" placeholder="MM-DD-YYYY" [max]="maxYear"
                   [matDatepicker]="picker" (focus)="picker.open()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker id="dateOfBirthButton" #picker></mat-datepicker>
          </mat-form-field>

          <div class="with-100">
            <label id="example-radio-group-label">Select Gender</label>
            <mat-radio-group fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-xs="15px"
                             formControlName="gender" [ngStyle.gt-xs]="{'padding': '10px 0 10px 0'}">
              <mat-radio-button class="example-radio-button" *ngFor="let gender of genderList" [value]="gender.value">
                {{gender.name}}
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <mat-form-field fxLayout="column" class="with-100" appearance="outline">
            <mat-label>Blood Group*</mat-label>
            <mat-select formControlName="bloodGroup">
              <mat-option *ngFor="let blood of bloodGroupList" [value]="blood.value">
                {{blood.Name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="with-100">
            <label id="radio-blood-label">Are you interested to donate blood?</label>
            <mat-radio-group fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-xs="15px"
                             formControlName="interestedDonateBlood" [ngStyle.gt-xs]="{'padding': '10px 0 10px 0'}">
              <mat-radio-button class="example-radio-button" *ngFor="let ans of bloodList" [value]="ans.value">
                {{ans.name}}
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <mat-form-field fxLayout="column" class="with-100" appearance="outline">
            <mat-label>City*</mat-label>
            <input matInput formControlName="city" placeholder="">
          </mat-form-field>

          <mat-form-field fxLayout="column" class="with-100" appearance="outline">
            <mat-label>State</mat-label>
            <mat-select formControlName="state" placeholder="">
              <mat-option *ngFor="let state of stateList" [value]="state.value">
                {{state.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <label class="mb-0"><strong>Upload passport size photo</strong></label>
          <div class="fileBox">

            <input type="file" #imgFile (change)="uploadFiles($event); onUploadChange($event)" accept=".png, .jpg, .jpeg"/>
          </div>
          <img *ngFor="let item of base64textString" width="150px" height="150px" src={{item}} alt="" id="img">

        </div>
      </form>

      <div class="mb-4 mt-10">
        <button mat-raised-button color="primary" [disabled]="!registerForm.valid" (click)="register()">Register</button>
      </div>
    </div>
  </div>

  <div *ngIf="!registerFormDisplay" style="height: 100vh">
    <div *ngIf="!registerFormDisplay" class="alert alert-success text-center mt-10" role="alert">
      Thank You for register. You will get digital Id card to registered mail id.
    </div>
  </div>
</div> -->

<!--<input type="file" #imgFile (change)="uploadFiles($event)" />-->
<!--<button mat-raised-button color="primary"  (click)="registerData()">Register</button>-->




<!-- <section class=" pad-10">
  <div class="container pt-5">
    <div class="row">
      <div class="col-3"></div>
      <form [formGroup]="registerForm">
        <div class="col-lg-6 pt-5 pb-5 login-border">
          <h2 class="text-center login-title">Member Register</h2>


          <div class="text-center login-btn">
            <button type="submit" class="btn login-button">Submit</button>
          </div>
        </div>
      </form>

    </div>
  </div>
</section> -->


<section class=" pad-10">
  <div class="container">
    <div class="row">
      <div class="col-3"></div>
      <div *ngIf="registerFormDisplay" class="col-lg-6 pt-5 pb-5 login-border">
        <h2 class="text-center login-title">Member Register</h2>

        <form [formGroup]="registerForm">
          <div fxLayout="column">
            <mat-form-field fxLayout="column">
              <mat-label>Full Name*</mat-label>
              <input matInput type="text" formControlName="fullName" placeholder="">
            </mat-form-field>

            <mat-form-field fxLayout="column">
              <mat-label>Email*</mat-label>
              <input type="email" matInput formControlName="email" placeholder="">
              <mat-error>Enter the Correct Email Id</mat-error>
            </mat-form-field>

            <mat-form-field fxLayout="column">
              <mat-label>Mobile Number*</mat-label>
              <input matInput type="number" onKeyPress="if(this.value.length==10) return false;" maxlength="10" min="1"
                formControlName="mobileNumber" placeholder="">
              <mat-error>Enter the Correct Mobile Number</mat-error>
            </mat-form-field>

            <mat-form-field fxLayout="column">
              <mat-label id="dateOfBirthLabel">DateOfBirth*</mat-label>
              <input matInput formControlName="dataOfBirth" placeholder="MM-DD-YYYY" [max]="maxYear"
                [matDatepicker]="picker" (focus)="picker.open()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker id="dateOfBirthButton" #picker></mat-datepicker>
            </mat-form-field>

            <div class="with-100">
              <label id="example-radio-group-label">Select Gender</label>
              <mat-radio-group fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-xs="15px" formControlName="gender"
                [ngStyle.gt-xs]="{'padding': '10px 0 10px 0'}">
                <mat-radio-button class="example-radio-button" *ngFor="let gender of genderList" [value]="gender.value">
                  {{gender.name}}
                </mat-radio-button>
              </mat-radio-group>
            </div>

            <mat-form-field fxLayout="column">
              <mat-label>Blood Group*</mat-label>
              <mat-select formControlName="bloodGroup">
                <mat-option *ngFor="let blood of bloodGroupList" [value]="blood.value">
                  {{blood.Name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="with-100">
              <label id="radio-blood-label">Are you interested to donate blood?</label>
              <mat-radio-group fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-xs="15px"
                formControlName="interestedDonateBlood" [ngStyle.gt-xs]="{'padding': '10px 0 10px 0'}">
                <mat-radio-button class="example-radio-button" *ngFor="let ans of bloodList" [value]="ans.value">
                  {{ans.name}}
                </mat-radio-button>
              </mat-radio-group>
            </div>

            <mat-form-field fxLayout="column">
              <mat-label>City*</mat-label>
              <input matInput formControlName="city" placeholder="">
            </mat-form-field>

            <mat-form-field fxLayout="column">
              <mat-label>State</mat-label>
              <mat-select formControlName="state" placeholder="">
                <mat-option *ngFor="let state of stateList" [value]="state.value">
                  {{state.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <label class="mb-0"><strong>Upload passport size photo</strong></label>
            <div class="fileBox">

              <input type="file" #imgFile (change)="uploadFiles($event); onUploadChange($event)"
                accept=".png, .jpg, .jpeg" />
            </div>
            <img *ngFor="let item of base64textString" width="150px" height="150px" src={{item}} alt="" id="img">

          </div>
        </form>
        <div class="text-center ">
          <button type="submit" class="btn login-button" (click)="register()">Submit</button>
        </div>
      </div>
      <div class="col-3"></div>
      <div *ngIf="!registerFormDisplay" style="height: 100vh">
        <div *ngIf="!registerFormDisplay" class="alert alert-success text-center mt-10" role="alert">
          Thank You for register. You will get digital Id card to registered mail id.
        </div>
      </div>
    </div>
  </div>
</section>

import { Component, OnInit, ViewChild } from '@angular/core';
import { DomainEmilaService } from 'src/app/services/domain-email-service/domain-emila.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-member-email-overview',
  templateUrl: './member-email-overview.component.html',
  styleUrls: ['./member-email-overview.component.scss']
})
export class MemberEmailOverviewComponent implements OnInit {

  dataSource;
  displayedColumns: string[] = ['id', 'name', 'officialEmail'];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(private domainEmilaService: DomainEmilaService) { }

  ngOnInit(): void {
    this.domainEmilaService.getDomainEmailDetails().subscribe(data => {
      this.dataSource = new MatTableDataSource(data.rows);
      this.dataSource.paginator = this.paginator;
    });
  }

}

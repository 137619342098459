import { Injectable } from '@angular/core';
import { ConfigurationService } from '../configuration/configuration.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  constructor(private http: HttpClient, private configurationService: ConfigurationService) { }


  insertNewVoucher(voucherDetails: any): Observable<any> {
    return this.http.post(this.configurationService.getHostUrl() + '/voucher/addVoucher', voucherDetails);
  }

  bulkUploadVoucher(voucherDetails: any): Observable<any> {
    return this.http.post(this.configurationService.getHostUrl() + '/voucher/bulkUploadVoucher', voucherDetails);
  }

  getAllVoucherData(): Observable<any> {
    return this.http.get(this.configurationService.getHostUrl() + '/voucher/getAllVoucherDetail');
  }

  getThisMonthDonation(): Observable<any> {
    return this.http.get(this.configurationService.getHostUrl() + '/voucher/getThisMonthDonation');
  }

  getMonthwiseDonation(details): Observable<any> {
    return this.http.post(this.configurationService.getHostUrl() + '/voucher/getMonthwiseDonation', details);
  }
}

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
  <div>
    <button mat-raised-button color="accent" [routerLink]="['/app/member']">Back</button>
  </div>
</div>


<div class="mt-10 mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> No </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="officialEmail">
      <th mat-header-cell *matHeaderCellDef> Official Email </th>
      <td mat-cell *matCellDef="let element"> {{element.domainEmail}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>
</div>

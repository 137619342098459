<!--<app-top-nav></app-top-nav>-->
<!-- <router-outlet></router-outlet>
<app-footer></app-footer> -->
<!--<section>
  <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
    <img src="assets/img/logo.png" id="logo" width="65" height="65" class="d-inline-block align-top" alt="">
    <span class="navbar-brand">Agarthulir Foundation</span>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" >About Us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" >Contact Us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" >Donate Us</a>
        </li>
      </ul>
    </div>
  </nav>
</section>
<section>
  <router-outlet></router-outlet>
</section>-->

<section>
  <nav class="navbar navbar-expand-lg navbar-light bg-light nav-color">

    <!--<nav class="navbar navbar-light">
      <a class="navbar-brand" href="#">
        <img src="assets/img/logo.png" width="55" height="55" class="d-inline-block align-top" alt="" loading="lazy">
        <span class="logo-name">Agarthulir Foundation</span>
      </a>
    </nav>-->
    <div class="cursor" style="text-align: center" routerLink="/">
      <img class="logo-img" src="assets/img/logo.png">
      <span class="logo-name">Agarthulir Foundation</span>
      <p id="demo"></p>
    </div>

    <!--    <a class="navbar-brand logo-name">Agarthulir Foundation</a>-->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/'">HOME</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/about'">ABOUT US</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/gallery'">GALLERY</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/contact'">CONTACT US</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/donate'">DONATE US</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/login">Login</a>
        </li>
      </ul>
    </div>
  </nav>
</section>
<div class="container">
  <router-outlet></router-outlet>
</div>

<footer class="mt-25" style="background-color: #fb8222">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
        <h2 class="white-back">Address</h2>
        <i class="white-back">5/121,Venkateshwara Nager,</i><br>
        <i class="white-back">Chikkarayapuram,</i><br>
        <i class="white-back">Chennai-69</i><br>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
        <h2 class="white-back">Contacts</h2>
        <i class="white-back">Email: info@agarthulir.com</i><br>
        <i class="white-back">Phone: +91 975 079 0961</i><br>
        <i class="white-back">WhatsApp: +91 979 119 5162</i><br>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
        <h2 class="white-back" [routerLink]="'/login'">Social Media</h2>
        <ul class="social-icons">
          <li><a class="facebook" href="https://www.facebook.com/agarthulir" target="_blank"><i
            class="fa fa-facebook"></i></a></li>
          <li><a class="twitter" href="https://twitter.com/agarthulir?s=09" target="_blank"><i
            class="fa fa-twitter"></i></a></li>
          <li><a class="dribbble" href="https://instagram.com/agarthulir_foundation?igshid=pwa0j3pt077f"
                 target="_blank"><i class="fa fa-instagram"></i></a></li>
          <li><a class="linkedin" href="https://www.linkedin.com/company/agarthulir" target="_blank"><i
            class="fa fa-linkedin"></i></a></li>
        </ul>
      </div>
    </div>

    <hr>

    <div class="text-center">
      <span class="text-light">&copy;2020 All rights reserved. Powered By Agarthulir Foundation</span>
    </div>
  </div>
</footer>

<!--I agree provide services to people irrespective of their gender, religion, age, colour, caste, and nationality

Thank you for your time in completing the registration form and for your interest in associating with Agarthulir Foundation.
Welcome to the Family of Agarthulir Foundation-->

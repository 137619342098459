import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../configuration/configuration.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http: HttpClient, private configurationService: ConfigurationService) {
  }

  loginCredential(loginCreditial: any): Observable<any> {
    return this.http.post(this.configurationService.getHostUrl() + '/login', {
      username: loginCreditial.email,
      password: loginCreditial.password
    });
  }
}

import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-donate-us-page',
  templateUrl: './donate-us-page.component.html',
  styleUrls: ['./donate-us-page.component.scss']
})
export class DonateUsPageComponent implements OnInit {

  donateAmountList = [
    {name: '₹ 100', value: 100},
    {name: '₹ 200', value: 200},
    {name: '₹ 500', value: 500},
    {name: '₹ 1000', value: 1000},
    {name: 'Other', value: 0},
  ];
  isDonateBtn = false;
  donateForm: FormGroup;
  userManualAmount = 0;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.donateForm = this.formBuilder.group({
      fullName: [''],
      email: [''],
      phone: [''],
      panNumber: [''],
      amount: ['']
    });
  }

  setAmountValue(value) {
    this.isDonateBtn = true;
    this.donateForm.get('amount').setValue(value);
    console.log(this.donateForm.value);
  }

  setUserAmount(event) {
    console.log(event);
    this.userManualAmount = event;
  }

  goToLink(){
    window.open(' https://frch.in/enTolD4EJab', "_blank");
}
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-member-overview',
  templateUrl: './member-overview.component.html',
  styleUrls: ['./member-overview.component.scss']
})
export class MemberOverviewComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  memberEmail() {
    this.router.navigate(['app/email-manage']);
  }
  memberDetails() {
    this.router.navigate(['app/member-manage']);
  }

  memberBirthdayList() {
    this.router.navigate(['app/member-birthday-list']);
  }

  memberSocialMedia() {
    this.router.navigate(['app/member-social-media-update']);
  }
}

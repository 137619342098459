<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="end center">
  <div>
    <button mat-raised-button color="accent" [routerLink]="['/app/voucher']">Back</button>
  </div>
</div>
<div class="mt-10 mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> Voucher Id </th>
      <td mat-cell *matCellDef="let element"> {{element.pk_id}} </td>
    </ng-container>

    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef> Date</th>
      <td mat-cell *matCellDef="let element"> {{element.donationDate | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="donaterName">
      <th mat-header-cell *matHeaderCellDef> Donater Name </th>
      <td mat-cell *matCellDef="let element"> {{element.donaterName}} </td>
    </ng-container>

    <ng-container matColumnDef="donatedAmount">
      <th mat-header-cell *matHeaderCellDef> Donated Amount </th>
      <td mat-cell *matCellDef="let element">₹ {{element.donatedAmount}} </td>
    </ng-container>

    <ng-container matColumnDef="donationBy">
      <th mat-header-cell *matHeaderCellDef> Donation Type </th>
      <td mat-cell *matCellDef="let element"> {{element.donationBy}} </td>
    </ng-container>

    <ng-container matColumnDef="panNumber">
      <th mat-header-cell *matHeaderCellDef> PAN No </th>
      <td mat-cell *matCellDef="let element"> {{element.panNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="transactionNumber">
      <th mat-header-cell *matHeaderCellDef> Transaction No </th>
      <td mat-cell *matCellDef="let element"> {{element.transactionNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="chequeNumber">
      <th mat-header-cell *matHeaderCellDef> Cheque No </th>
      <td mat-cell *matCellDef="let element"> {{element.chequeNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Download </th>
      <td mat-cell *matCellDef="let element">

        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="getSelectedRow(element)">
          <mat-icon>cloud_download</mat-icon>
        </button>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>
</div>

<mat-menu #menu="matMenu">

  <section mat-menu-item >
    <mat-checkbox class="example-margin" (click)="doSomething($event, 1);" [checked]="withSeal">With Seal</mat-checkbox>
  </section>

  <section mat-menu-item >
    <mat-checkbox class="example-margin" (click)="doSomething($event, 2)" [checked]="withOutSeal">WithOut Seal</mat-checkbox>
  </section>

  <button mat-menu-item (click)="downloadPdf()">
    <mat-icon>save_alt</mat-icon>
    <span>Download</span>
  </button>
</mat-menu>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {

  imageObject = [
    // {
    //   video: 'https://youtu.be/tYa6OLQHrEc',
    //   title: 'Youtube example one with title.',
    //   alt: 'youtube video'
    // },
    {
      image: 'assets/imgWeb/event_photo/abj/5.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/5.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/2.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/2.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/3.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/3.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/4.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/4.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/1.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/1.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/6.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/6.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/7.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/7.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/8.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/8.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/9.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/9.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/10.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/10.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/11.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/11.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/12.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/12.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/13.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/13.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/14.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/14.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/15.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/15.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/16.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/16.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/17.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/17.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/18.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/18.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/19.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/19.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/20.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/20.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/21.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/21.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/22.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/22.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/23.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/23.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/24.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/24.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/25.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/25.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/26.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/26.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/27.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/27.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/28.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/28.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/29.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/29.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/30.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/30.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/abj/31.JPG',
      thumbImage: 'assets/imgWeb/event_photo/abj/31.JPG',
    },
  ];

  imageObject1 = [
    {
      image: 'assets/imgWeb/event_photo/August_10_Meeting/12.jpg',
      thumbImage: 'assets/imgWeb/event_photo/August_10_Meeting/12.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/August_10_Meeting/13.jpg',
      thumbImage: 'assets/imgWeb/event_photo/August_10_Meeting/13.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/August_10_Meeting/5.jpg',
      thumbImage: 'assets/imgWeb/event_photo/August_10_Meeting/5.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/August_10_Meeting/2.jpg',
      thumbImage: 'assets/imgWeb/event_photo/August_10_Meeting/2.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/August_10_Meeting/3.jpg',
      thumbImage: 'assets/imgWeb/event_photo/August_10_Meeting/3.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/August_10_Meeting/4.jpg',
      thumbImage: 'assets/imgWeb/event_photo/August_10_Meeting/4.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/August_10_Meeting/1.jpg',
      thumbImage: 'assets/imgWeb/event_photo/August_10_Meeting/1.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/August_10_Meeting/6.jpg',
      thumbImage: 'assets/imgWeb/event_photo/August_10_Meeting/6.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/August_10_Meeting/7.jpg',
      thumbImage: 'assets/imgWeb/event_photo/August_10_Meeting/7.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/August_10_Meeting/8.jpg',
      thumbImage: 'assets/imgWeb/event_photo/August_10_Meeting/8.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/August_10_Meeting/9.jpg',
      thumbImage: 'assets/imgWeb/event_photo/August_10_Meeting/9.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/August_10_Meeting/10.jpg',
      thumbImage: 'assets/imgWeb/event_photo/August_10_Meeting/10.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/August_10_Meeting/11.jpg',
      thumbImage: 'assets/imgWeb/event_photo/August_10_Meeting/11.jpg',
    },

    {
      image: 'assets/imgWeb/event_photo/August_10_Meeting/14.jpg',
      thumbImage: 'assets/imgWeb/event_photo/August_10_Meeting/14.jpg',
    },
  ];

  imageObject2 = [
    {
      image: 'assets/imgWeb/event_photo/gurumalai/5.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/5.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/2.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/2.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/3.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/3.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/4.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/4.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/1.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/1.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/6.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/6.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/7.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/7.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/8.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/8.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/9.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/9.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/10.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/10.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/11.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/11.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/12.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/12.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/13.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/13.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/14.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/14.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/15.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/15.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/16.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/16.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/17.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/17.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/18.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/18.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/19.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/19.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/20.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/20.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/21.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/21.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/22.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/22.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/23.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/23.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/24.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/24.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/gurumalai/25.JPG',
      thumbImage: 'assets/imgWeb/event_photo/gurumalai/25.JPG',
    },
  ];

  imageObject3 = [
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/5.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/5.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/2.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/2.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/3.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/3.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/4.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/4.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/1.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/1.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/6.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/6.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/7.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/7.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/8.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/8.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/9.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/9.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/10.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/10.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/11.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/11.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/12.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/12.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/13.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/13.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/14.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/14.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/15.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/15.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/16.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/16.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/17.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/17.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/18.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/18.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/19.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/19.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/20.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/20.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/21.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/21.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/22.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/22.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/Jesus_trust/23.JPG',
      thumbImage: 'assets/imgWeb/event_photo/Jesus_trust/23.JPG',
    },
  ];

  imageObject4 = [
    {
      image: 'assets/imgWeb/event_photo/namakal/7.jpg',
      thumbImage: 'assets/imgWeb/event_photo/namakal/7.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/namakal/5.jpg',
      thumbImage: 'assets/imgWeb/event_photo/namakal/5.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/namakal/2.jpg',
      thumbImage: 'assets/imgWeb/event_photo/namakal/2.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/namakal/3.jpg',
      thumbImage: 'assets/imgWeb/event_photo/namakal/3.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/namakal/1.jpg',
      thumbImage: 'assets/imgWeb/event_photo/namakal/1.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/namakal/6.jpg',
      thumbImage: 'assets/imgWeb/event_photo/namakal/6.jpg',
    },

    {
      image: 'assets/imgWeb/event_photo/namakal/8.jpg',
      thumbImage: 'assets/imgWeb/event_photo/namakal/8.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/namakal/4.jpg',
      thumbImage: 'assets/imgWeb/event_photo/namakal/4.jpg',
    },
  ];

  imageObject5 = [
    {
      image: 'assets/imgWeb/event_photo/shore_home/5.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/5.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/2.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/2.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/3.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/3.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/4.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/4.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/1.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/1.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/6.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/6.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/7.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/7.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/8.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/8.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/9.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/9.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/10.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/10.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/11.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/11.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/12.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/12.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/13.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/13.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/14.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/14.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/15.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/15.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/16.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/16.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/17.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/17.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/18.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/18.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/19.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/19.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/20.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/20.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/21.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/21.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/22.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/22.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/23.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/23.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/24.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/24.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/25.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/25.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/26.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/26.JPG',
    },
    {
      image: 'assets/imgWeb/event_photo/shore_home/27.JPG',
      thumbImage: 'assets/imgWeb/event_photo/shore_home/27.JPG',
    },
  ];

imageObject6 = [

    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/2.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/2.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/3.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/3.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/4.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/4.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/1.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/1.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/5.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/5.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/6.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/6.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/7.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/7.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/8.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/8.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/9.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/9.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/10.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/10.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/11.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/11.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/12.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/12.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/13.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/13.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/14.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/14.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/15.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/15.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/16.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/16.jpg',
    },
    {
      image: 'assets/imgWeb/event_photo/CLEAN_INDIA/17.jpg',
      thumbImage: 'assets/imgWeb/event_photo/CLEAN_INDIA/17.jpg',
    },
  ];

  imageObject7 = [
    {
      video: 'https://www.youtube.com/watch?v=ZFwXUegJKww',
      title: 'Agarthulir',
      alt: 'youtube video'
    },
    {
      video: 'https://www.youtube.com/watch?v=8ww4oR-Hm5Y',
      title: 'Agarthulir',
      alt: 'youtube video'
    },
    {
      video: 'https://www.youtube.com/watch?v=C37593mJzFc&t=6s',
      title: 'Agarthulir',
      alt: 'youtube video'
    },
    {
      video: 'https://www.youtube.com/watch?v=B-S57QdskC8',
      title: 'Agarthulir',
      alt: 'youtube video'
    },
  ]
  constructor() {
  }

  ngOnInit(): void {
  }

}

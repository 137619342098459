<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="space-between center">
  <div>
    <input class="file-upload" #inputFile type="file" (change)="onFileChange($event)" accept=".xlsx, .xls, .csv"
      multiple="false" />
  </div>
  <div>
    <button *ngIf="showTable" mat-raised-button (click)="uploadFile()">Upload</button>
  </div>
  <div>
    <button mat-raised-button (click)="export()">Download sample file</button>
  </div>
  <div>
    <button mat-raised-button color="accent" [routerLink]="['/app/voucher']">Back</button>
  </div>
</div>

<div class="mt-10" *ngIf="showTable">
  <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.date}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="donaterName">
      <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.donaterName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="panNumber">
      <mat-header-cell *matHeaderCellDef>PAN No</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.panNumber}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="addressLine1">
      <mat-header-cell *matHeaderCellDef> Address 1 </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.addressLine1}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="addressLine2">
      <mat-header-cell *matHeaderCellDef> Address 2 </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.addressLine2}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="donatedAmount">
      <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.donatedAmount}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="donatedAmountLetters">
      <mat-header-cell *matHeaderCellDef> Amount Letter</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.donatedAmountLetters}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="donationBy">
      <mat-header-cell *matHeaderCellDef> DonationBy </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.donationBy}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="transactionNumber">
      <mat-header-cell *matHeaderCellDef> Transaction No </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.transactionNumber}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="chequeNumber">
      <mat-header-cell *matHeaderCellDef> ChequeNo </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.chequeNumber}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="instrumentDate">
      <mat-header-cell *matHeaderCellDef> InstrumentDate </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.instrumentDate}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="signature">
      <mat-header-cell *matHeaderCellDef> Signature </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.signature}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="signaturePersonValue">
      <mat-header-cell *matHeaderCellDef> SignaturePersonValue </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.signaturePersonValue}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="signaturePerson">
      <mat-header-cell *matHeaderCellDef> SignaturePerson </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.signaturePerson}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="signaturePersonPosstion">
      <mat-header-cell *matHeaderCellDef> SignaturePersonPosstion </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.signaturePersonPosstion}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>

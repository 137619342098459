import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {DomSanitizer} from '@angular/platform-browser';
import {MatDialog} from '@angular/material/dialog';
import {PersonLightBoxComponent} from '../person-light-box/person-light-box.component';
import {MembersService} from '../../../services/members/members.service';
import {MatSort} from '@angular/material/sort';
import * as XLSX from 'xlsx';
import {BookType} from 'xlsx';
import * as FileSaver from 'file-saver';


@Component({
  selector: 'app-members-details',
  templateUrl: './members-details.component.html',
  styleUrls: ['./members-details.component.scss']
})
export class MembersDetailsComponent implements OnInit {
  EXCEL_MIME_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  dataSource;
  displayedColumns: string[] = ['imageBase64textString', 'firstName', 'email', 'mobile', 'blood_group', 'id_card_status', 'settingsBtn'];

  sampleHeader = [
    ['date', 'donaterName', 'panNumber', 'addressLine1', 'addressLine2',
      'donatedAmount', 'donatedAmountLetters', 'donationBy',
      'transactionNumber', 'chequeNumber', 'instrumentDate',
      'signaturePersonValue', 'signaturePersonPosstion'],
    ['2020-07-02', 'Name', 'pan number', 'Address 1', 'Address 2',
      100, 'One Thousand Rupees Only', 'BHIM-UPI',
      'id', '', '', 1, 'Founder & Chairman']
  ];
  fileName = 'sampleFile.xlsx';

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer,
              private spinner: NgxSpinnerService, public dialog: MatDialog, private membersService: MembersService) {
    this.activatedRoute.data.subscribe((data: any) => {
      const tempData = data.allMemberDetail.result;
      this.dataSource = new MatTableDataSource(tempData);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });

  }

  ngOnInit() {
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  openPersonDialog(element) {
    const dialogRef = this.dialog.open(PersonLightBoxComponent, {
      disableClose: true,
      hasBackdrop: true,
      data: element,
      width: '60%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.result) {
        // tslint:disable-next-line:no-shadowed-variable
        this.membersService.getAllMembers().subscribe((result: any) => {
          this.dataSource.data = [];
          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });
          this.dataSource.data = result.result;
          setTimeout(() => {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });
        }, error => {
        });
      }
    });
  }

  export() {

    // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.dataSource.data);

    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // XLSX.writeFile(wb, this.fileName);

    this.export_with_headers(this.dataSource.data, 'test', 'test', ['']);
  }

  export_with_headers(rows: any[], fileName: string, type: string, headers: string[]) {
    const worksheet: XLSX.WorkSheet = this.json_to_sheet_with_headers(rows, headers);

    const workbook: XLSX.WorkBook = {Sheets: {[type]: worksheet}, SheetNames: [type]};
    const excelBuffer = this.write(workbook, 'xlsx');

    const blob = this.build([excelBuffer], {type: this.EXCEL_MIME_TYPE});

    this.saveAs(blob, fileName + '.xlsx');
  }

  json_to_sheet_with_headers(jsonArray: object[], headers: string[]): XLSX.WorkSheet {
    return XLSX.utils.json_to_sheet(jsonArray, {header: headers});
  }

  write(workbook: XLSX.WorkBook, type: BookType): any {
    return XLSX.write(workbook, {bookType: type, type: 'array'});
  }

  saveAs(blob: Blob, filename: string) {
    FileSaver.saveAs(blob, filename);
  }

  build(bufferArray: Array<any>, options: { type: string }): Blob {
    return new Blob(bufferArray, options);
  }

}

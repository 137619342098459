import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  AFTeamMember = [

    {
      name: 'Veeramani',
      position: 'Director',
      photo: 'assets/images/admin/veeramani.png'
    },
    {
      name: 'Aravinth',
      position: 'Program Manager',
      photo: 'assets/images/admin/aravinth.png'
    },
    {
      name: 'Mathidharan',
      position: 'IT Executive Officer',
      photo: 'assets/images/admin/mathi.png',
    },
    {
      name: 'Shrinathan',
      position: 'Account Executive Officer',
      photo: 'assets/images/admin/shrinath.png'
    },
    {
      name: 'Thilagavathi',
      position: 'Document Analyst',
      photo: 'assets/images/admin/thilaga.png'
    },
    {
      name: 'Harish',
      position: 'Public Relation Officer',
      photo: 'assets/images/admin/harish.png'
    },
    {
      name: 'Sabarees',
      position: 'Admin Manager',
      photo: 'assets/images/admin/sabari.png'
    },
    {
      name: 'Praveen',
      position: 'Event Co-Ordinator',
      photo: 'assets/images/admin/praven.png'
    },

    {
      name: 'Purushothaman',
      position: 'Project Co-Ordinator',
      photo: 'assets/images/admin/purushothaman.png'
    },
    {
      name: 'Loga Priya',
      position: 'Security Engineer',
      photo: 'assets/images/admin/logapriya.png'
    },
    {
      name: 'Meena',
      position: 'Media Advisor',
      photo: 'assets/images/admin/meena.png'
    },
    {
      name: 'Rajesh',
      position: 'Project Co-Ordinator',
      photo: 'assets/images/admin/rajesh.png'
    },
    {
      name: 'Venkatesh',
      position: 'Project Co-Ordinator',
      photo: 'assets/images/admin/venkatesh.png'
    },
    {
      name: 'Vivek',
      position: 'Project Co-Ordinator',
      photo: 'assets/images/admin/vivke.png'
    },
    {
      name: 'Damodharan',
      position: 'Project Co-Ordinator',
      photo: 'assets/images/admin/damo.png'
    },
    {
      name: 'Gawtham',
      position: 'Project Co-Ordinator',
      photo: 'assets/images/admin/gowtham.png'
    },
    {
      name: 'Akhil',
      position: 'Project Co-Ordinator',
      photo: 'assets/images/admin/akil.png'
    },
    {
      name: 'Venkatesan',
      position: 'Project Co-Ordinator',
      photo: 'assets/images/admin/venky.png'
    },

  ];

  journyList = [
    {
      year: '2012',
      month1: 'Jul',
      month2: 'Nov',
      text1: 'Started our first mission by visiting indigent orphanage and provided one day food and other essentials',
      text2: 'Educational support. Visited tribal village Gurumalai near Vellore, adopted students and supported their academic studies'
    },
    {
      year: '2013',
      month1: 'Mar',
      month2: 'Oct',
      text1: 'Blood donation camp. “Every blood donor is a life saver”. Organized blood donation camp near Chennai and our volunteers donated their blood.',
      text2: 'Registration of NGO. Next milestone of our journey. Registered Agarthulir foundation as NGO officially and got approval from the government.'
    },
    {
      year: '2014',
      month1: 'Jul',
      month2: 'Nov',
      text1: 'Educational support. Our second adoption process. Marched towards Namakal and adopted students who are economically backward and supported their entire academic studies.',
      text2: 'Conducted Clean India mission program at Marina beach, Chennai. Our members actively participated and cleaned the beach amidst heavy rain. Agarthulir was the first NGO to conduct clean India mission in Tamilnadu.'
    },
    {
      year: '2015',
      month1: 'May',
      month2: 'Nov',
      text1: 'Supplied Food Products to the Orphanages near Karur area',
      text2: 'Vardah Cyclone remedy. Chennai and Cuddalore was severely affected due to the cyclone and the city were sinking in flood. Agarthulir helped 350+ families by supplying essentials.'
    },
    {
      year: '2016',
      month1: 'Jan',
      month2: 'Jul',
      text1: 'Organ donation awareness program “Log in before you log Out” Our beloved Director and Actor “Mr. Cheran “was chief guest for the program and provided the valuable guidance to the people and our organization.',
      text2: 'Conducted First year Memorial Day for our beloved “DR APG Abdul kalam. MR. Pa Ponraj was the special guest and shared his unforgettable memories with APG.'
    },
    {
      year: '2017',
      month1: 'Jan',
      month2: 'Jul',
      text1: 'Jallikattu Protest. The most treasured heirlooms are the values of our tradition that we pass down to the future generation. Agarthulir supported by joining hands for Jallikattu protest in Marina beach, Chennai.',
      text2: 'Conducted Blood Donation Camp at Stanley Hospital.'
    },
    {
      year: '2018',
      month1: 'Mar',
      month2: 'Nov',
      text1: '“Every blood donor is a life saver” keeping this in mind our volunteers came forwarded and donated the blood in blood camp conducted by us near Arakonam.',
      text2: 'Extended support for GAJA cyclone remedy. Supported 125 + Families by providing food and essentials. Constructed 25 houses for people who lost their houses due to cyclone.'
    },
    {
      year: '2019',
      month1: 'Jun',
      month2: 'Aug',
      text1: 'Adopted Day Spring Orphanage in Chennai and started supporting every month for their monthly expenses.',
      text2: 'Medical support. Contributed to a school student who was affected by brain tumor for treatment and recovery.'
    },
    {
      year: '2020',
      month1: 'Apr',
      month2: 'Oct',
      text1: 'COVID 19 remedy. The outbreak of corona virus shook the entire world and the worst affected are daily wagers. In a motive to support the peoples who are in need, Agarthulir started survey and provided food and essentials required for cooking. 480+ families were benefited.',
      text2: 'Started ScholarOne – Student Adoption Program and planning to launch in 2021.'
    },
  ]
  constructor() {
  }

  ngOnInit(): void {
  }

}

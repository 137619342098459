<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
  <mat-card class="main-card" fxLayoutAlign="center" (click)="voucherBulkUpload()">
    Voucher Bulk Upload
  </mat-card>

  <mat-card class="main-card" fxLayoutAlign="center" (click)="generateVoucher()">
    Generate New Voucher
  </mat-card>

  <mat-card class="main-card" fxLayoutAlign="center" (click)="viewVoucherReport()">
    View Over all Voucher Report
  </mat-card>

  <mat-card class="main-card" fxLayoutAlign="center" (click)="monthwiseVoucherReport()">
    Monthwise Voucher Report
  </mat-card>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-navgation',
  templateUrl: './side-navgation.component.html',
  styleUrls: ['./side-navgation.component.scss']
})
export class SideNavgationComponent implements OnInit {

  showMenu = false;
  routes = [
    {
      path: 'dashboard',
      data: { icon: 'dashboard', text: 'Dashboard' }
    },
    {
      path: 'charts',
      data: { icon: 'bar_chart', text: 'Charts' }
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}

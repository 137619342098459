import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../configuration/configuration.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DomainEmilaService {

  constructor(private http: HttpClient, private configurationService: ConfigurationService) { }

  getDomainEmailDetails(): Observable<any> {
    return this.http.get(this.configurationService.getHostUrl() + '/domainEmail');
  }

}

<div fxLayout="row" class="lightbox-header">
  <div fxFlex="75" fxLayoutAlign="start center" class="lightbox-title">Member Details</div>
  <mat-icon fxFlex="25" (click)="dialogClose()" fxLayoutAlign="end center" class="cursor-pointer">close</mat-icon>
</div>
<mat-divider></mat-divider>
<mat-dialog-content>
  <mat-tab-group>
    <!--    Basic Tab-->
    <mat-tab label="Basic Info">
      <div class="mt-5" fxLayout="row" fxLayoutGap="10px">
        <div fxLayout="column" fxFlex="50">
          <mat-label class="font-weight-bold">First Name</mat-label>
          <mat-form-field appearance="outline">
            <input matInput [value]="displayMemberData.firstName" readonly>
          </mat-form-field>

          <mat-label class="font-weight-bold">Email Address</mat-label>
          <mat-form-field appearance="outline">
            <input matInput [value]="displayMemberData.email" readonly>
          </mat-form-field>

          <mat-label class="font-weight-bold">Mobile Number</mat-label>
          <mat-form-field appearance="outline">
            <input matInput [value]="displayMemberData.mobile" readonly>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxFlex="50">
          <mat-label class="font-weight-bold">Gender</mat-label>
          <mat-form-field appearance="outline">
            <input matInput [value]="displayMemberData.gender" readonly>
          </mat-form-field>

          <mat-label class="font-weight-bold">Blood Group</mat-label>
          <mat-form-field appearance="outline">
            <input matInput [value]="displayMemberData.blood_group" readonly>
          </mat-form-field>

          <mat-label class="font-weight-bold">Date Of Birth</mat-label>
          <mat-form-field appearance="outline">
            <input matInput [value]="displayMemberData.dateOfBirth | date" readonly>
          </mat-form-field>
        </div>
      </div>
    </mat-tab>
    <!--    ID Card Info Tab-->
    <mat-tab label="ID Card Info">
      <form [formGroup]="idCardForm">
        <div class="mt-5" fxLayout="row" fxLayoutGap="10px">
          <div fxLayout="column" fxFlex="50">
            <mat-label class="font-weight-bold">ID Card Number</mat-label>
            <mat-form-field appearance="outline">
              <input matInput
                     [value]="displayMemberData.id_card_number_prefix + displayMemberData.id_card_number_suffix"
                     disabled>
            </mat-form-field>

            <mat-label class="font-weight-bold">ID Card Validity</mat-label>
            <mat-form-field appearance="outline">
              <input matInput [matDatepicker]="picker" formControlName="id_card_validity">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxFlex="50">
            <mat-label class="font-weight-bold">ID Card Status</mat-label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="id_card_status">
                <mat-option *ngFor="let ststus of idCardStatus" [value]="ststus.value">
                  {{ststus.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="mt-25">
              <mat-checkbox formControlName="is_generate_id_card_number"
                            [checked]="idCardForm.value.is_generate_id_card_number">Generate ID Card Number
              </mat-checkbox>
            </div>
          </div>
        </div>
      </form>

      <div align="end" fxLayoutGap="10px">
        <button mat-raised-button color="primary" [disabled]="!checkDisabled()" (click)="updateIDCardStatus()">Submit
        </button>
      </div>

    </mat-tab>
  </mat-tab-group>


</mat-dialog-content>
<!--
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
</mat-dialog-actions>
-->

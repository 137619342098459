import {Component, OnInit} from '@angular/core';
import {VoucherService} from 'src/app/services/voucher/voucher.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  monthInLetter;
  fundByThisMonth = 0;
  idCardStatus;

  constructor(private voucherService: VoucherService, private activatedRoute: ActivatedRoute) {

    this.activatedRoute.data.subscribe(data => {
      this.idCardStatus = data.idCardStatus[0];
    });

    const today = new Date();
    this.monthInLetter = today.toLocaleString('default', {month: 'long'});

    this.voucherService.getThisMonthDonation().subscribe(data => {
      const calAmount = data.result;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < calAmount.length; i++) {
        this.fundByThisMonth = this.fundByThisMonth + calAmount[i].donatedAmount;
      }
    });
  }

  ngOnInit() {

  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-birthday-list',
  templateUrl: './birthday-list.component.html',
  styleUrls: ['./birthday-list.component.scss']
})
export class BirthdayListComponent implements OnInit {

  memberList;

  constructor(private activatedRoute: ActivatedRoute) {

    this.activatedRoute.data.subscribe((data: any) => {
      this.memberList = data.memberBirthdayDetail;
    });


  }

  ngOnInit(): void {
  }

}

import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NotificationServiceService} from '../../../services/notificationService/notification-service.service';
import {ContactUsService} from '../../../services/contact-us/contact-us.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public contactForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private router: Router, public contactUsService: ContactUsService,
              private notificationServiceService: NotificationServiceService) {

  }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      emailId: [''],
      message: ['', Validators.required]
    });
  }

  sendMessage() {
    if (this.contactForm.valid) {
      this.contactUsService.sendMail(this.contactForm.value).subscribe(data => {
        console.log(data);
      });
      this.notificationServiceService.showSnackBar('success', 'Thank You, Agarthulir team will contact you soon', 3000);

      this.resetFormValue();
    }
  }

  formValied(): boolean {
    return this.contactForm.valid;
  }

  resetFormValue() {
    this.contactForm.get('name').setValue('');
    this.contactForm.get('phoneNumber').setValue('');
    this.contactForm.get('emailId').setValue('');
    this.contactForm.get('message').setValue('');

    this.contactForm.markAsUntouched();
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import { DonationTypeService } from 'src/app/services/donation-type/donation-type.service';

@Injectable()
export class DonationTypeResolver implements Resolve<any> {

  constructor(private donationTypeService: DonationTypeService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.donationTypeService.getAllDonationType();
  }


}

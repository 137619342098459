<section class="container-90 pad-10">
  <h2 class="pb-3 pt-5" style="font-weight: bold;color: #F69520;font-size: 45px;">Information</h2>
  <p><span style="font-weight: bold;font-size: 25px;">Agarthulir Foundation</span> was formed by a group of students in
    the year 2012 and functions effectively to support
    peoples in their need. We are contributing our support to the educational and medical needs, orphanages and
    disaster relief.</p>
  <p> We are contributing our support to the educational and medical needs, orphanages and
    disaster relief. </p>
  <p> Our recent activities include,</p>

</section>

<section class="container pt-2">
  <div class="">
    <div class="row">
      <div class="col-lg-6 pt-3">
        <div style="width: 90%;display: flex;">
          <div style="width: 20%">
            <img src="./assets/images/medi.svg" alt="">
          </div>
          <div style="width: 80%">
            <h3>Medical support</h3>
            <p>We supported a Covid positive dialysis patient for the dialysis treatment.</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 pt-3">
        <div style="width: 90%;display: flex;">
          <div style="width: 20%">
            <img src="./assets/images/education.svg" alt="">
          </div>
          <div style="width: 80%">
            <h3>Educational Support </h3>
            <p>We supported a student from Madurai to cover his annual fee</p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 pt-3">
        <div style="width: 90%;display: flex;">
          <div style="width: 20%">
            <img src="./assets/images/sheild.svg" alt="">
          </div>
          <div style="width: 80%">
            <h3>Disaster Relief: Covid-19 relief</h3>
            <p> Areas covered Tirunelveli, Erode, Ponneri (Chennai). We provided essential roceries to 420 daily wagers
              families</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mt-5">
  <div class="container contact-outline">
    <div class="row">
      <div class="col-lg-6 pt-5 pb-5">
        <mat-form-field fxLayout="column" appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput type="text" placeholder="">
        </mat-form-field>

        <mat-form-field fxLayout="column" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput type="text" placeholder="">
        </mat-form-field>

        <mat-form-field fxLayout="column" appearance="outline">
          <mat-label>Phone Number</mat-label>
          <input matInput type="text" placeholder="">
        </mat-form-field>

        <mat-form-field fxLayout="column" appearance="outline">
          <mat-label>Message</mat-label>
          <textarea rows="7" matInput></textarea>
        </mat-form-field>

        <div class="text-center mt-5">
          <button type="submit" class="btn submit-btn">Submit</button>
        </div>
      </div>

      <div class="col-lg-6 p-5 contactus">
        <div class="p-5 contact-information">
          <h2>Contact Us
          </h2>
          <ul class="ul-type">
            <li class="mt-5">
              <img src="./assets/images/Location.png" alt="">
              <span class="pl-4">
                <span>5/121,Venkateshwara Nager,</span><br>
                <span class="pl-5"> Chikkarayapuram,</span><br>
                <span class="pl-5"> Chennai-69.</span>
              </span>
            </li>
            <li class="mt-4">
              <img src="./assets/images/email.png" alt="">
              <span class="pl-4">
                info@agarthulir.com
              </span>
            </li>
            <li class="mt-4">
              <img src="./assets/images/icon/call icon.svg" alt="">
              <span class="pl-4">
                +91 975 079 0961
              </span>
            </li>
            <li class="mt-4">
              <img src="./assets/images/Icon awesome-whatsapp.svg" alt="">
              <span class="pl-4">
                +91 979 119 5162
              </span>
            </li>
          </ul>
          <ul class="navbar-ul pt-5">
            <li>
              <a href="https://www.facebook.com/agarthulir.india">
                <img src="./assets/images/Icon awesome-facebook-f.svg" alt="">
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/agarthulir_foundation/">
                <img src="./assets/images/Icon awesome-instagram.svg" alt="">
              </a>

            </li>
            <li>
              <a href="https://twitter.com/agarthulir"></a>
              <img src="./assets/images/Icon awesome-twitter.svg" alt="">
            </li>
            <li>
              <a></a>
              <img src="./assets/images/linkedin-in.svg" alt="">
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UC5LDsyvFehbGEAtcA2XE_MQ">
                <img src="./assets/images/youtube.svg" alt="">
              </a>

            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>


<div class="container mt-3">
  <!-- <div class="col"> -->
  <iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas"
    src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=5/121,Venkateshwara%20Nager,Chikkarayapuram%20Chennai+(Agarthulir)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
  <script type='text/javascript'
    src='https://embedmaps.com/google-maps-authorization/script.js?id=fca91a0b3d5ba2ad7aac539b12ab5f9752f1774a'></script>
  <!-- </div> -->

</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#ff6600" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading ... </p>
</ngx-spinner>

<div class="layout-container">
  <app-top-navgation (sideNavToggled)="snav.toggle()"></app-top-navgation>

  <mat-sidenav-container class="sidenav-container" [style.marginTop.px]="toolBarHeight">
    <mat-sidenav #snav class="sidenav mat-elevation-z10" [(mode)]="sideNavMode" [(opened)]="sideNavOpened">
      <app-side-navgation></app-side-navgation>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="main-container">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>


import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { MembersService } from 'src/app/services/members/members.service';
import { NotificationServiceService } from 'src/app/services/notificationService/notification-service.service';

@Component({
  selector: 'app-member-social-media-details',
  templateUrl: './member-social-media-details.component.html',
  styleUrls: ['./member-social-media-details.component.scss']
})

export class MemberSocialMediaDetailsComponent implements OnInit {

  dataSource;
  displayedColumns: string[] = ['firstName', 'email', 'mobile', 'is_added_whatsapp', 'is_added_email', 'action'];
  editedIndex;
  updateMemberDetail;


  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private activatedRoute: ActivatedRoute, private membersService: MembersService, private notificationServiceService: NotificationServiceService) {

    this.activatedRoute.data.subscribe((data: any) => {
      let tempData = data.memberSocialMediaDetail
      tempData.map((data) => {
        data.isEdited = false;
      })
      this.dataSource = new MatTableDataSource(tempData);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    });
  }

  ngOnInit(): void {
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  updateCheckBoxValue(index, element, checkBox) {
    this.editedIndex = index;
    this.updateMemberDetail = element;

    if (checkBox === 1) {
      this.updateMemberDetail.is_added_whatsapp = !this.updateMemberDetail.is_added_whatsapp
    } else if (checkBox === 2) {
      this.updateMemberDetail.is_added_email = !this.updateMemberDetail.is_added_email
    }
  }

  updateMember() {
    this.membersService.updateSocialMediaDetail(this.updateMemberDetail).subscribe(result => {
      if(result.status){
        this.notificationServiceService.showSnackBar('success', 'Updated Succeed', 3000);
        this.reBuildTable();
      } else {
        this.notificationServiceService.showSnackBar('error', 'Update Failed', 3000);
      }
    })
    this.editedIndex = null;
  }

  reBuildTable() {
    this.membersService.getSocialMediaDetails().subscribe(result => {
      this.dataSource.data = result;
    })
  }

}

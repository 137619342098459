<!-- <div class="container pb-5">
  <h1 class="text-center">Donate</h1>

  <div class="row">
    <div class="col-sm-6 text-center">
      <label fxLayout="column" class="fs-20">
        <em>A generous heart worth more than million genius minds</em>
      </label>
      <label fxLayout="column" class="fs-20 my-4">
        <em>Aid us in making better chances in life for deserving people</em>
      </label>
      <label fxLayout="column" class="fs-20 my-4">
        <em>Scan to donate</em>
      </label>
      <label fxLayout="column" class="fs-20 my-4">
        <em>Using BHIM UPI/Cards/wallets</em>
      </label>

      <div>
        <img class="with-100" src="assets/imgWeb/payment-card.png"/>
      </div>
      <div class="fs-20">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Google Pay</a>
            <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Phonepe</a>

          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <div>
              <img class="with-100" src="assets/imgWeb/payment-card.png"/>
            </div>
          </div>
          <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
            <div>
              <img class="with-100" src="assets/imgWeb/payment-card.png"/>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <form [formGroup]="donateForm">
        <mat-form-field fxLayout="column" class="with-100" appearance="outline">

          <input matInput type="text" formControlName="fullName" placeholder="Name">
        </mat-form-field>

        <mat-form-field fxLayout="column" class="with-100" appearance="outline">

          <input matInput formControlName="phone" placeholder="Phone Number">
        </mat-form-field>

        <mat-form-field fxLayout="column" class="with-100" appearance="outline">

          <input matInput type="email" formControlName="email" placeholder="Email Id">
        </mat-form-field>

        <mat-form-field fxLayout="column" class="with-100" appearance="outline">

          <input matInput type="text" formControlName="panNumber" placeholder="PAN Number">
        </mat-form-field>

        <div>
          <h1 id="example-radio-group-label">Select Donation Money</h1>
          <mat-radio-group fxLayoutGap="10px" formControlName="amount">
            <mat-radio-button *ngFor="let donate of donateAmountList" (change)="setAmountValue(donate.value)"
                              [value]="donate.value">
              <span style="font-size: 16px">{{donate.name}}</span>
            </mat-radio-button>
          </mat-radio-group>

        </div>
      </form>

      <div class="text-center mt-3" *ngIf="isDonateBtn">
        <button mat-raised-button *ngIf="donateForm.value.amount !== 0" color="primary">Click to
          Donate {{donateForm.value.amount | currency:'INR'}}</button>

        <div *ngIf="donateForm.value.amount === 0">
          <mat-form-field fxLayout="column" class="with-100" appearance="outline">
            <mat-label>Enter Amount Manually</mat-label>
            <span style="font-size: 30px" matPrefix>₹ &nbsp;</span>
            <input matInput type="number" min="1" [ngModel]="userManualAmount" (ngModelChange)="setUserAmount($event)"
                   placeholder="">
          </mat-form-field>

          <button mat-raised-button color="primary">Click to Donate {{userManualAmount | currency:'INR'}}</button>

        </div>
      </div>

    </div>
  </div>
</div> -->



<section class=" pad-10">
  <div class="container pt-5">
    <div class="row">
      <div class="col-8">
        <div class="card text-center">
          <div class="card-body">
            <div class="card">
              <div class="card-body">
                <h3><strong> Gpay Number:</strong> 9791195162 </h3>
                <h3> <strong> UPI ID:</strong> 9791195162-1@okbizaxis</h3>
              </div>
            </div>
            <br>

            <div class="card">
              <div class="card-body">
                <h3>Internet Banking/Debit/Credit Card - Click bellow button</h3>
                <button class="button" (click)="goToLink()" >Donate</button>
              </div>
            </div>

            <br>

            <div class="card">
              <div class="card-body">
                <p>Please share your Name, Personal Email ID, Mobile Number, and Address mentioning the donated Amount.
                  So that we can send you the Donation Voucher & share updates on our Future programmes and current
                  progress with you.
                </p>
                <p>
                  To know the BANK Details or for further queries please WhatsApp to 9791195162 or reach us at
                  agarthulir@gmail.com
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card text-center">
          <div class="card-body">
            <img width="100%" src="/assets/images/admin/gpay3.jpg">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

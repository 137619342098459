<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
  <mat-card class="main-card" fxLayoutAlign="center" (click)="memberDetails()">
    Member Details
  </mat-card>

  <mat-card class="main-card" fxLayoutAlign="center" (click)="memberEmail()">
    Member Email Details
  </mat-card>

  <mat-card class="main-card" fxLayoutAlign="center" (click)="memberBirthdayList()">
    Member Birthday Details
  </mat-card>

  <mat-card class="main-card" fxLayoutAlign="center" (click)="memberSocialMedia()">
    Member SocialMedia Details
  </mat-card>

</div>

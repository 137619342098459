<mat-toolbar color="primary" class="fix-nav">
  <button type="button" mat-icon-button class="visible-md" (click)="toggleSidebar()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <div class="nav-brand">
    Agarthulir Admin
  </div>
  <span class="nav-spacer"></span>
  <!-- <form class="hidden-sm" style="margin-right: 20px; margin-top: 8px">
    <mat-form-field style="font-size: 1rem;">
      <input matInput placeholder="Search">
    </mat-form-field>
  </form>
  <a  class="hidden-sm" mat-raised-button style="margin-right: 10px">
    <mat-icon>cloud_download</mat-icon> Download Now
  </a> -->
  <button class="hidden-sm" mat-icon-button [matMenuTriggerFor]="profile">
    <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #profile="matMenu" [overlapTrigger]="false">
    <button mat-menu-item>
      <mat-icon>person</mat-icon>
      <span>Profile</span>
    </button>
    <button mat-menu-item>
      <mat-icon>inbox</mat-icon>
      <span>Inbox</span>
    </button>
    <button mat-menu-item>
      <mat-icon>settings</mat-icon>
      <span>Settings</span>
    </button>
  </mat-menu>
  <button mat-icon-button (click)="onLoggedout()">
    <mat-icon>exit_to_app</mat-icon>
  </button>
</mat-toolbar>

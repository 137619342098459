<div fxLayout="row" fxLayout.md="column" fxLayout.sm="column" fxLayoutGap="10px">
  <div fxFlex="30">
    <mat-card class="card-overview">
      <span class="title" fxLayoutAlign="center">Collected donations by {{monthInLetter}}</span>
      <mat-card-content class="card1">
        <span class="subTitle">Total: </span>₹ {{fundByThisMonth}}
      </mat-card-content>
    </mat-card>
  </div>



  <div fxFlex="30">
    <mat-card class="card-overview">
      <span class="title" fxLayoutAlign="center">Total member register count</span>
      <mat-card-content class="card1">
        <div fxLayout="column" >
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="countTitle">Request Received: </span> <span>{{idCardStatus.requestReceived}}</span>
          </div>
        </div>

        <div fxLayout="column" class="">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="countTitle">ID Card in Process: </span> <span>{{idCardStatus.iDCardinProcess}}</span>
          </div>
        </div>

        <div fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <span class="countTitle">Delivered : </span> <span>{{idCardStatus.delivered}}</span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-voucher-overview',
  templateUrl: './voucher-overview.component.html',
  styleUrls: ['./voucher-overview.component.scss']
})
export class VoucherOverviewComponent implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe((data) => {
      // console.log('Data :', data);
    });
  }

  ngOnInit() {

  }

  generateVoucher() {
    this.router.navigate(['app/add-voucher']);
  }

  viewVoucherReport() {
    this.router.navigate(['app/report-voucher']);
  }

  voucherBulkUpload() {
    this.router.navigate(['app/bulk-upload-voucher']);
  }

  monthwiseVoucherReport() {
    this.router.navigate(['app/monthwise-report-voucher']);
  }



}

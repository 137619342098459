<section class=" container-90 pt-5">
  <div class="pad-10">
    <img src="assets/images/donation Cover img.png" class="bord" width="100%" alt="">
  </div>
</section>

<section class=" container-90 pt-5">
  <div class="text-center">
    <h1 class="pb-3 welcome-title">Welcome to
      Agarthulir</h1>
    <p class="pb-4">Let's Sow the Seeds for the Needs
      A random small act of kindness makes wonders for many...!</p>
    <a class="join-us"  (click)="onEdit()" [routerLink]="'/memberRegister'">Join Us</a>
  </div>
</section>

<section>
  <div class="container-90 pt-5">
    <div class="row">
      <div class="col-lg-6 text-center mt-5">
        <div>
          <h2 class="vision-title">Vision</h2>
          <div class="vision-underline">
          </div>
        </div>
        <div class="mt-5" style="display: block;">
          <img src="assets/images/icon vision.png" alt="">
        </div>
      </div>
      <div class="col-lg-4 text-center mt-5">
        <p class="text-left pt-5">To establish rehabilitation centre for support less cancer
          patients and other people to cover medical expenses. To
          help financially backward people with no discriminations
          (like caste, sex, creed, religion) by supporting them for
          their education. In this rapidly modernizing world where
          nothing is permanent, education is the only means to bring
          social economic upliftment of the society</p>
      </div>
    </div>
  </div>
</section>

<section class="pt-5 container-90">
  <div class="text-center mission">
    <h3>Mission</h3>
    <div class="mission-underline"></div>
    <p>Do things for people not because of who they are or what they do in
      return, but because of who you are !!</p>
  </div>
</section>

<section class=" container-90 mt-5">
  <div class="text-center">
    <h1 class="pb-3 socialupdate">Social
      Updates</h1>
    <h4><span style="font-weight: 900;"> Follow</span> @Agarthulir foundation</h4>
  </div>

  <div class="row">
    <div class="col-lg-4 text-center">
      <!-- <img src="assets/images/iphone.png" class="facebook-mobile" alt=""> -->
      <iframe
    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fagarthulir&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
    width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true"
    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </div>
    <div class="col-lg-8 text-center mob-gal">
      <img src="assets/images/clean india image@2x.png" width="80%" alt="">
    </div>
  </div>
  <!-- </div> -->
</section>

<section class=" container-90 ">
  <div class="row text-center">
    <div class="col-lg-4 text-center mt-5">
      <div class="services-1">
        <img src="assets/images/map-school.svg" width="100px" height="100px" alt="">
        <p>Students benefitting
          from scholarship</p>
      </div>
    </div>
    <div class="col-lg-4 text-center mt-5">
      <div class="services-2">
        <img src="assets/images/hands-helping.svg" width="100px" height="100px" alt="">
        <p>Orphanages supported with
          daily expenses
        </p>
      </div>
    </div>
    <div class="col-lg-4 text-center mt-5">
      <div class="services-3">
        <img src="assets/images/briefcase-medical.svg" width="100px" height="100px" alt="">
        <p>Patients helped to meet
          medical expenses
        </p>
      </div>
    </div>
  </div>
</section>

<section class=" container-90  mt-5">
  <div class="row">
    <div *ngFor="let data of reviewList" class="col-lg-4 text-center">
      <figure class="snip1192">
        <blockquote>{{data.review}}</blockquote>
        <div class="author">
          <img src="{{data.url}}" alt="sq-sample1" />
          <h5>{{data.name}}<span> {{data.designation}} </span></h5>
        </div>
      </figure>
    </div>
  </div>

</section>
<!--
<div class="col-4">
  <iframe
    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fagarthulir&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
    width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true"
    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
</div>


<div class="container">
  <img src="assets/images/iphone.png" alt="Snow">
  <div class="centered">
    <iframe
      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fagarthulir&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
       style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
  </div>
</div> -->

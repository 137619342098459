import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MembersService } from '../../../services/members/members.service';
import { formatDate } from '@angular/common';
import { NotificationServiceService } from '../../../services/notificationService/notification-service.service';
import { error } from 'selenium-webdriver';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-member-register',
  templateUrl: './member-register.component.html',
  styleUrls: ['./member-register.component.scss']
})
export class MemberRegisterComponent implements OnInit {

  registerForm: FormGroup;
  dataPassing = new FormData();

  bloodGroupList = [
    { Name: 'A +ve', value: 'A +ve' },
    { Name: 'A -ve', value: 'A -ve' },
    { Name: 'B +ve', value: 'B +ve' },
    { Name: 'B -ve', value: 'B -ve' },
    { Name: 'O +ve', value: 'O +ve' },
    { Name: 'O -ve', value: 'O -ve' },
    { Name: 'AB +ve', value: 'AB +ve' },
    { Name: 'AB -ve', value: 'AB -ve' },
  ];

  genderList = [
    { name: 'Male', value: 'Male' },
    { name: 'Female', value: 'Female' },
    { name: 'Transgender ', value: 'Transgender' },
  ];

  bloodList = [
    { name: 'Yes', value: 'Yes' },
    { name: 'No', value: 'No' },
  ];

  stateList = [
    { no: 1, name: 'Andhra Pradesh', value: 'Andhra Pradesh' },
    { no: 2, name: 'Arunachal Pradesh', value: 'Arunachal Pradesh' },
    { no: 3, name: 'Assam', value: 'Assam' },
    { no: 4, name: 'Bihar', value: 'Bihar' },
    { no: 5, name: 'Chhattisgarh', value: 'Chhattisgarh' },
    { no: 6, name: 'Goa', value: 'Goa' },
    { no: 7, name: 'Gujarat', value: 'Gujarat' },
    { no: 8, name: 'Haryana', value: 'Haryana' },
    { no: 9, name: 'Himachal Pradesh', value: 'Himachal Pradesh' },
    { no: 10, name: 'Jharkhand', value: 'Jharkhand' },
    { no: 11, name: 'Karnataka', value: 'Karnataka' },
    { no: 12, name: 'Kerala', value: 'Kerala' },
    { no: 13, name: 'Madhya Pradesh', value: 'Madhya Pradesh' },
    { no: 14, name: 'Maharashtra', value: 'Maharashtra' },
    { no: 15, name: 'Manipur', value: 'Manipur' },
    { no: 16, name: 'Meghalaya', value: 'Meghalaya' },
    { no: 17, name: 'Mizoram', value: 'Mizoram' },
    { no: 18, name: 'Nagaland', value: 'Nagaland' },
    { no: 19, name: 'Odisha', value: 'Odisha' },
    { no: 20, name: 'Punjab', value: 'Punjab' },
    { no: 21, name: 'Rajasthan', value: 'Rajasthan' },
    { no: 22, name: 'Sikkim', value: 'Sikkim' },
    { no: 23, name: 'Tamil Nadu', value: 'Tamil Nadu' },
    { no: 24, name: 'Telangana', value: 'Telangana' },
    { no: 25, name: 'Tripura', value: 'Tripura' },
    { no: 26, name: 'Uttar Pradesh', value: 'Uttar Pradesh' },
    { no: 27, name: 'Uttarakhand', value: 'Uttarakhand' },
    { no: 28, name: 'West Bengal', value: 'West Bengal' },
  ];
  maxYear = new Date();
  registerFormDisplay = true;

  imageSrc: any;
  base64textString = [];

  @ViewChild('inputFile') myInputVariable: ElementRef;

  @ViewChild('imgFile') myInputVariable1;

  constructor(private formBuilder: FormBuilder, public membersService: MembersService,
    private spinner: NgxSpinnerService, public notificationServiceService: NotificationServiceService) {
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      mobileNumber: ['', [Validators.required, Validators.maxLength(10)]],
      dataOfBirth: ['', Validators.required],
      gender: [''],
      bloodGroup: ['', Validators.required],
      interestedDonateBlood: [''],
      city: ['', Validators.required],
      state: [''],
      imageBase64textString: ['']
    });
  }

  register() {
    if (this.registerForm.value.dataOfBirth) {
      this.registerForm.get('dataOfBirth').setValue(formatDate(this.registerForm.value.dataOfBirth, 'dd-MM-yyyy', 'en-US', '+0530'));
    }
    this.spinner.show();
    this.membersService.getDataImage(this.dataPassing).then((result: any) => {
      if (result.fileId) {
        this.registerForm.get('imageBase64textString').setValue(result.fileId);
        this.membersService.register(this.registerForm.value).subscribe(memberResult => {
          if (memberResult.pkId) {
            this.spinner.hide();
            this.registerFormDisplay = false;
          } else {
            this.spinner.hide();
          }
        }, error => {
          this.spinner.hide();
          this.notificationServiceService.showSnackBar('error', 'Something went wrong. Please try again.', 3000);
        });
      }
    }, error => {
      this.spinner.hide();
      this.notificationServiceService.showSnackBar('error', 'Something went wrong. Please try again.', 3000);
    });


  }

  uploadFiles(files) {
    this.dataPassing.append('file', this.myInputVariable1.nativeElement.files[0], this.myInputVariable1.nativeElement.files[0]['name']);
  }

  onUploadChange(evt: any) {
    const file = evt.target.files[0];
    this.base64textString = [];
    if (file) {
      const reader = new FileReader();

      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  handleReaderLoaded(e) {
    this.base64textString.push('data:image/png;base64,' + btoa(e.target.result));
  }

}

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center">
  <div>
    <button mat-raised-button color="accent" [routerLink]="['/app/member']">Back</button>
  </div>

  <div class="example-header">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>
  </div>
</div>


<div class="mt-10 mat-elevation-z8">
  <table class="with-100" mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
      <td mat-cell *matCellDef="let element">{{element.firstName}}</td>
    </ng-container>

    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile</th>
      <td mat-cell *matCellDef="let element"> {{element.mobile}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="is_added_whatsapp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> WhatsApp</th>
      <td mat-cell *matCellDef="let element, let i = index">
        <section class="example-section">
          <!-- <mat-checkbox [checked]="element.is_added_whatsapp" (click)="updateCheckBoxValue(i, element, 1)"></mat-checkbox> -->
          <input class="form-check-input ml-3" type="checkbox" [checked]="element.is_added_whatsapp" value="element.is_added_whatsapp" (click)="updateCheckBoxValue(i, element, 1)">
        </section>
      </td>
    </ng-container>

    <ng-container matColumnDef="is_added_email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email Group</th>
      <td mat-cell *matCellDef="let element, let i = index">
        <section class="example-section">
          <!-- <mat-checkbox [checked]="element.is_added_email" (click)="updateCheckBoxValue(i, element, 2)"></mat-checkbox> -->
          <input class="form-check-input ml-3" type="checkbox" [checked]="element.is_added_email" value="element.is_added_email" (click)="updateCheckBoxValue(i, element, 2)">
        </section>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element, let i = index">
        <button mat-raised-button color="primary" *ngIf="editedIndex === i" (click)="updateMember()">Update</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
  </mat-paginator>
</div>

<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#ff6600" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading ... </p>
</ngx-spinner>

<router-outlet></router-outlet> -->

<header>
  <div class="header-web container-90 mt-3">
    <div class="row">
      <div class="col-2">
        <img [routerLink]="'/'" (click)="onEdit()" src="assets/images/logo.png" alt="">
      </div>
      <div class="col-8 mt-4 ">
        <ul class="navbar-ul">
          <li><a [routerLink]="'/'">Home</a></li>
          <li><a [routerLink]="'/about'">About us</a></li>
          <li><a [routerLink]="'/gallery'">Gallery</a></li>
          <li><a [routerLink]="'/contact'">Contact us</a></li>
          <li><a [routerLink]="'/memberRegister'">Join us</a></li>
        </ul>
        <div class="nav-line mt-4"></div>
      </div>
      <div class="col-2 mt-4">
        <a class="donate-btn" [routerLink]="'/donate'">Donate</a>
      </div>
    </div>
  </div>

  <div class="header bg-color-fa" style="background-color: white;position: fixed;">
    <nav class="navbar">
      <a>
        <img class="logo" src="assets/images/logo.png" alt="">
      </a>
      <ul class="nav-links">
        <li><a [routerLink]="'/'">Home</a></li>
        <li><a [routerLink]="'/about'">About us</a></li>
        <li><a [routerLink]="'/gallery'">Gallery</a></li>
        <li><a [routerLink]="'/contact'">Contant us</a></li>
        <li><a [routerLink]="'/memberRegister'">Join us</a></li>
        <button onclick="scroll(0, 100);">click to scroll to the 100th pixel</button>
      </ul>
      <div class="hamburger-menu">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
      </div>
    </nav>
  </div>
</header>


<router-outlet></router-outlet>



<footer class="mt-5 footer">
  <div class="pt-5 pb-5 footer-container">
    <div class="row">
      <div class="col-lg-6 mt-5">
        <h2>Contact Us
        </h2>
        <ul class="ul-type">
          <li class="mt-5">
            <img src="assets/images/Location icon.svg" alt="">
            <span class="pl-4">
              5/121,Venkateshwara Nager, Chikkarayapuram,
              Chennai-69.</span>
          </li>
          <li class="mt-4">
            <img src="assets/images/mail icon.svg" alt="">
            <span class="pl-4">
              info@agarthulir.com
            </span>
          </li>
          <li class="mt-4">
            <img src="assets/images/call icon.svg" alt="">
            <span class="pl-4">
              +91 975 079 0961
            </span>
          </li>
          <li class="mt-4">
            <img src="assets/images/whatsapp.svg" alt="">
            <span class="pl-4">
              +91 979 119 5162
            </span>
          </li>
          <li class="pt-5">
            <a class="donate-here-btn" (click)="onEdit()" [routerLink]="'/donate'">Donate
              Here
              <img src="assets/images/Donate Rupee Icon.svg" alt="">
            </a>
          </li>
        </ul>
      </div>
      <div class="col-lg-6  ">
        <div class="row">
          <div class="col-lg-6 mt-5">
            <h2>Useful links
            </h2>
            <ul class="footer-links">
              <li class="mt-5">
                <a [routerLink]="'/'" (click)="onEdit()">Home</a>
              </li>
              <li class="mt-4">
                <a [routerLink]="'/about'" (click)="onEdit()">About us</a>
              </li>
              <li class="mt-4">
                <a [routerLink]="'/gallery'" (click)="onEdit()">Gallery</a>
              </li>
              <li class="mt-4">
                <a [routerLink]="'/contact'" (click)="onEdit()">Contact us</a>
              </li>
              <li class="mt-4">
                <a [routerLink]="'/contact'" (click)="onEdit()">Join us</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-6 mt-5">
            <h2>Follow us on
            </h2>
            <ul class="footer-links">
              <li class="mt-5">
                <a href="https://www.facebook.com/agarthulir.india">
                  <img src="assets/images/Icon awesome-facebook-f.svg" alt="">
                  <span class="pl-3"> Facebook</span>
                </a>
              </li>
              <li class="mt-4">
                <a href="https://www.instagram.com/agarthulir_foundation/">
                  <img src="assets/images/Icon awesome-instagram.svg" alt="">
                  <span class="pl-3"> Instagram </span>
                </a>
              </li>
              <li class="mt-4">
                <a href="https://twitter.com/agarthulir">
                  <img src="assets/images/Icon awesome-twitter.svg" alt="">
                  <span class="pl-3"> Twitter </span>
                </a>
              </li>
              <li class="mt-4">
                <a>
                  <img src="assets/images/linkedin-in.svg" alt="">
                  <span class="pl-3"> Linkedin </span>
                </a>
              </li>
              <li class="mt-4">
                <a href="https://www.youtube.com/channel/UC5LDsyvFehbGEAtcA2XE_MQ">
                  <img src="assets/images/youtube.svg" alt="">
                  <span class="pl-3">
                    Youtube
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row pt-3">
          <div>
            <div class="container__item">
              <form class="form">
                <input type="email" class="form__field" placeholder="Enter Email" />
                <button type="button" class="btn btn--primary btn--inside uppercase">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

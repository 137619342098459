import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigurationService} from '../configuration/configuration.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private http: HttpClient, private configurationService: ConfigurationService) {
  }

  sendMail(mailInfo: any): Observable<any> {
    return this.http.post(this.configurationService.getHostUrl() + '/contact/mail', mailInfo);
  }
}

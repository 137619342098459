<div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end">
  <div>
    <button mat-raised-button color="accent" [routerLink]="['/app/voucher']">Back</button>
  </div>
</div>

<div fxLayoutAlign="center center">
  <mat-card fxLayoutAlign="center" style="width: 60%;">
    <form [formGroup]="voucherForm">
      <mat-card-header fxLayoutAlign='center'>
        <mat-card-title>
          Voucher Details
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput [matDatepicker]="voucherPicker" formControlName="date" placeholder="Voucher Date" readonly>
          <mat-datepicker-toggle matSuffix [for]="voucherPicker"></mat-datepicker-toggle>
          <mat-datepicker #voucherPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput placeholder="Donater Name*" formControlName="donaterName">
          <mat-error>Enter Donater Name</mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Donor PAN Number" formControlName="panNumber">
          <mat-error>Enter Donor PAN Number</mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput placeholder="Address Line 1" formControlName="addressLine1">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput placeholder="Address Line 2" formControlName="addressLine2">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput type="number" min="1" placeholder="Donated Amount*"
            (ngModelChange)="setAmountInLetter($event)" formControlName="donatedAmount">
          <mat-error>Enter Donated Amount</mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input readonly matInput type="text" placeholder="(IN LETTERS)*" formControlName="donatedAmountLetters">
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Donation By*</mat-label>
          <mat-select formControlName="donationBy" (selectionChange)="setDonationType($event)">
            <mat-option *ngFor="let donation of donationList" [value]="donation.name">
              {{donation.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="voucherForm.value.donationBy === 'Cheque'" class="example-full-width">
          <input matInput placeholder="Cheque Number*" formControlName="chequeNumber">
        </mat-form-field>

        <mat-form-field
          *ngIf="voucherForm.value.donationBy === 'Online Bank Transfer' || voucherForm.value.donationBy === 'BHIM-UPI'"
          class="example-full-width">
          <input matInput placeholder="Transaction Number" formControlName="transactionNumber">
        </mat-form-field>

        <mat-form-field *ngIf="voucherForm.value.donationBy === 'Cheque'" class="example-full-width">
          <input matInput [matDatepicker]="picker" formControlName="instrumentDate" placeholder="Instrument date"
            readonly>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px">
          <mat-checkbox [checked]="isSeal" (change)="isWOSDownload($event)">Agarthulir Without Seal</mat-checkbox>
          <mat-checkbox formControlName="signature">Authorized Signature*</mat-checkbox>
        </div>

        <mat-form-field *ngIf="voucherForm.value.signature" class="example-full-width">
          <mat-label>Authorized Person*</mat-label>
          <mat-select formControlName="signaturePersonValue">
            <mat-option *ngFor="let admin of adminList" [value]="admin.value">
              {{admin.name}} - {{admin.posstion}}
            </mat-option>
          </mat-select>
          <mat-error>Select any one</mat-error>
        </mat-form-field>

      </mat-card-content>

      <div fxLayoutAlign="end">
        <button mat-raised-button color="primary" [disabled]="checkDisable()" (click)="generateVoucher()">
          <mat-icon>cloud_download</mat-icon>Generate Voucher
        </button>
      </div>

    </form>

  </mat-card>
</div>


<!-- <div class="voucherOverview">
  <div fxLayout="row">
    <div fxFlex="80">

      <div class="contact-card" fxLayout="row" fxLayoutAlign="center center">
        <form [formGroup]="voucherForm">
          <mat-card>
            <mat-card-header fxLayoutAlign='center'>
              <mat-card-title>
                Voucher Details
              </mat-card-title>
            </mat-card-header>
            <mat-card-content>

              <mat-form-field class="example-full-width">
                <input matInput [matDatepicker]="voucherPicker" formControlName="date" placeholder="Voucher Date"
                  readonly>
                <mat-datepicker-toggle matSuffix [for]="voucherPicker"></mat-datepicker-toggle>
                <mat-datepicker #voucherPicker></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="example-full-width">
                <input matInput placeholder="Donater Name*" formControlName="donaterName">
                <mat-error>Enter Donater Name</mat-error>
              </mat-form-field>

              <mat-form-field class="example-full-width">
                <input matInput placeholder="Donater PAN Number*" formControlName="donaterName">
                <mat-error>Enter Donater PAN Number</mat-error>
              </mat-form-field>

              <mat-form-field class="example-full-width">
                <input matInput placeholder="Address Line 1" formControlName="addressLine1">
              </mat-form-field>

              <mat-form-field class="example-full-width">
                <input matInput placeholder="Address Line 2" formControlName="addressLine2">
              </mat-form-field>

              <mat-form-field class="example-full-width">
                <input matInput type="number" min="1" placeholder="Donated Amount*"
                  (ngModelChange)="setAmountInLetter($event)" formControlName="donatedAmount">
                <mat-error>Enter Donated Amount</mat-error>
              </mat-form-field>

              <mat-form-field class="example-full-width">
                <input readonly matInput type="text" placeholder="(IN LETTERS)*" formControlName="donatedAmountLetters">
              </mat-form-field>

              <mat-form-field class="example-full-width">
                <mat-label>Donation By*</mat-label>
                <mat-select formControlName="donationBy" (selectionChange)="setDonationType($event)">
                  <mat-option *ngFor="let donation of donationList" [value]="donation.name">
                    {{donation.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field *ngIf="voucherForm.value.donationBy === 'Cheque'" class="example-full-width">
                <input matInput placeholder="Cheque Number" formControlName="chequeNumber">
              </mat-form-field>

              <mat-form-field *ngIf="voucherForm.value.donationBy === 'Cheque'" class="example-full-width">
                <input matInput [matDatepicker]="picker" formControlName="instrumentDate" placeholder="Instrument date"
                  readonly>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

              <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px">
                <mat-checkbox [checked]="isSeal" (change)="isWOSDownload($event)">Agarthulir Without Seal</mat-checkbox>
                <mat-checkbox formControlName="signature">Authorized Signature*</mat-checkbox>
              </div>

              <mat-form-field *ngIf="voucherForm.value.signature" class="example-full-width">
                <mat-label>Authorized Person*</mat-label>
                <mat-select formControlName="signaturePersonValue">
                  <mat-option *ngFor="let admin of adminList" [value]="admin.value">
                    {{admin.name}} - {{admin.posstion}}
                  </mat-option>
                </mat-select>
                <mat-error>Select any one</mat-error>
              </mat-form-field>


            </mat-card-content>

            <div fxLayoutAlign="end">
              <button mat-raised-button color="primary" [disabled]="checkDisable()" (click)="generateVoucher()">
                <mat-icon>cloud_download</mat-icon>Generate Voucher
              </button>
            </div>
          </mat-card>
        </form>
      </div>
    </div>
    <div fxFlex="20">
      <button mat-raised-button color="accent" (click)="backToVoucherOverview()">Back</button>
    </div>
  </div>

</div> -->

<!-- <div *ngIf="!voucherDisable" class="mt-10" fxLayout="row" fxLayoutAlign="space-around">
  <button mat-raised-button color="primary" (click)="back()">Back</button>
  <form [formGroup]="voucherForm">
    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px">
      <mat-checkbox formControlName="seal">Agarthulir Seal</mat-checkbox>
      <mat-checkbox formControlName="signature"  >Authorized Signature</mat-checkbox>
    </div>
  </form>

  <button mat-raised-button color="primary" >Download Voucher</button>
</div> -->


<!--
<div *ngIf="!voucherDisable" id="MyDIv">
  <table style="width:100%;  font-size: 16px;margin-top: 50px;">
    <tr>
      <td style="text-align: center"> <img src="assets/img/logo.png"> </td>
      <td style="text-align: center;">
        <strong><span
            style="font-size: 40px; font-family: Times New Roman, Times, serif; color: rgb(255, 153, 51);">Agarthulir
            Foundation</span></strong>
        <br>
        <span>5/121, VENKATESHWARA NAGAR, CHIKKARAPAPURAM, CHENNAI 600069.</span>
        <br>
        <span>WEBSITE: www.agarthulir.org&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E-Mail: agarthulir@gmail.com</span>
        <br>
        <span>CONTACT NO: +91-9750790961&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WHATSAPP: +91-9791195162</span>
      </td>
      <td style="text-align: center;">
        <strong><span>Register Number : 221/2013/IV</span></strong>
        <br>
        <br>
        <span
          style="border-radius:50%; border:solid black 1px;padding:15px; background-color: antiquewhite;color: black;"><strong>Donation
            Voucher</strong></span>
        <br>
        <br>
        <span><strong>PAN:AAETA7642Q</strong></span>
      </td>
    </tr>

    <tr style="height: 30px;">
      <td colspan="2"><span style="padding-left: 25px;">Receipt Number : AF20-XXXXX</span></td>
      <td style="text-align: start;"><span>Date: {{today | date: 'dd/MM/yyyy'}}</span></td>
    </tr>

    <tr style="height: 30px;">
      <td style="padding-left: 80px;" colspan="3">
        <div fxLayout="row">
          <div style="width: 15%;"><span> Donated By</span></div>
          <div><span>: {{voucherForm.value.donaterName}}</span></div>
        </div>
      </td>
    </tr>
    <tr style="height: 30px;">
      <td style="padding-left: 80px;" colspan="3">
        <div fxLayout="row">
          <div style="width: 15%;"><span>Address</span></div>
          <div><span>: {{voucherForm.value.addressLine1}}</span> <br> <span>  {{voucherForm.value.addressLine2}}</span> </div>
        </div>
      </td>
    </tr>
    <tr style="height: 30px;">
      <td style="padding-left: 80px;" colspan="3">
        <div fxLayout="row">
          <div style="width: 15%;"><span>Donated Value</span></div>
          <div><span>: {{voucherForm.value.donatedAmount}}</span></div>
        </div>
      </td>
    </tr>
    <tr style="height: 30px;">
      <td style="padding-left: 80px;" colspan="3">
        <div fxLayout="row">
          <div style="width: 15%;"><span>(In Numbers)</span></div>
          <div><span>: {{voucherForm.value.donatedAmountLetters}}</span></div>
        </div>
      </td>
    </tr>



    <tr style="height: 120px;vertical-align: bottom;">
      <td style="text-align: center;">
        <img *ngIf="voucherForm.value.seal" width="130px"  src="assets/img/Signature/seal.JPG"><br>
        <span>Seal</span>
      </td>
      <td style="text-align: center;"><span>Thank you for the generosity. We appreciate your support !</span>
        <br>
        <span><strong><em> Let’s sow the seeds for the needs !!!</em></strong></span>
      </td>
      <td style="text-align: center;">
        <img *ngIf="voucherForm.value.signature || !voucherForm.value.signaturePerson" width="130px"  src={{voucherForm.value.signaturePerson}}>
        <br>
        <span>Authorized Signature</span>
      </td>
    </tr>



  </table>
</div>


-->

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-site-overview',
  templateUrl: './web-site-overview.component.html',
  styleUrls: ['./web-site-overview.component.scss']
})
export class WebSiteOverviewComponent implements OnInit {

  i = 0;
  txt = new String('Lorem ipsum dummy text blabla.');
  speed = 50;
  constructor() { }

  ngOnInit(): void {
    this.typeWriter();
  }

  typeWriter() {
    if (this.i < this.txt.length) {
      document.getElementById("demo").innerHTML += this.txt.charAt(this.i);
      this.i++;
      setTimeout(this.typeWriter, this.speed);
    }
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MembersService } from '../../../services/members/members.service';
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { error } from 'selenium-webdriver';
import { NotificationServiceService } from '../../../services/notificationService/notification-service.service';

@Component({
  selector: 'app-person-light-box',
  templateUrl: './person-light-box.component.html',
  styleUrls: ['./person-light-box.component.scss']
})
export class PersonLightBoxComponent implements OnInit {

  idCardForm: FormGroup;
  idCardStatus = [
    {
      name: 'Request Received',
      value: 1
    },
    {
      name: 'ID Card in process',
      value: 2
    },
    {
      name: 'Delivered',
      value: 3
    }
  ];
  displayMemberData;
  isRefresh = false;

  constructor(@Inject(MAT_DIALOG_DATA) public memberData: any, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<PersonLightBoxComponent>,
    private membersService: MembersService, private spinner: NgxSpinnerService, private notificationServiceService: NotificationServiceService) {

    this.displayMemberData = memberData;
    this.setIDCardDetails(memberData);
  }

  ngOnInit() {
  }

  setIDCardDetails(memberData) {
    this.idCardForm = this.formBuilder.group({
      id: memberData.id,
      id_card_validity: [memberData.id_card_validity],
      id_card_status: [memberData.id_card_status],
      is_generate_id_card_number: [memberData.is_generate_id_card_number]
    });

    this.displayMemberData.id_card_number_suffix = this.displayMemberData.id_card_number_suffix ? ('0000' + this.displayMemberData.id_card_number_suffix.toString()).slice(-4) : this.displayMemberData.id_card_number_suffix;

  }

  updateIDCardStatus() {
    if (this.idCardForm.value.id_card_validity) {
      this.idCardForm.get('id_card_validity').setValue(formatDate(this.idCardForm.value.id_card_validity, 'dd-MM-yyyy', 'en-US', '+0530'));
    }

    this.spinner.show();
    this.membersService.updateIDCardStatus(this.idCardForm.value).subscribe(result => {
      this.isRefresh = true;
      if (result.status) {
        this.getMemberDetailById();
      } else {
        this.spinner.hide();
        this.notificationServiceService.showSnackBar('error', 'Update Failed', 3000);
      }
    }, error => {
      console.error(error);
      this.spinner.hide();
      this.notificationServiceService.showSnackBar('error', 'Update Failed', 3000);

    });
  }

  getMemberDetailById() {
    this.membersService.getMemberById(this.idCardForm.value.id).subscribe(result => {
      this.displayMemberData = result.result[0];
      this.setIDCardDetails(result.result[0]);
      this.spinner.hide();
      this.notificationServiceService.showSnackBar('success', 'Updated Successfully', 3000);
    }, () => {
      this.spinner.hide();
      this.notificationServiceService.showSnackBar('error', 'Update Failed', 3000);

    });
  }

  checkDisabled() {
    return this.idCardForm.dirty;
  }

  dialogClose() {
    this.dialogRef.close({ result: this.isRefresh });
  }
}
